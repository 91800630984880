import Link from 'next/link';
import styled from 'styled-components';
import {useContext, useEffect, useRef, useState} from 'react';
import AppContext from '../../AppContext';
import CallrailButton from '../../CallrailButton';
import VobAktifyCta from 'aac-components/components/VobAktifyCta';
import IconShield from 'aac-components/components/Icons/Basic/IconShield';
import IconChat from 'aac-components/components/Icons/Communication/IconChat';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import VobApp from 'aac-components/components/VobApp';
import AktifyForm from 'aac-components/components/AktifyForm';
import VobHeader from './VobHeader';
import {PagePillContext} from '..';
import HardcodedPillStyles from './HardcodedPillStyles';
import SmsFormAbandonModal from 'aac-components/components/AktifyForm/SmsFormAbandonModal';
import {useMentalHealthVob} from '../../../lib/utils';

const SameDayAdmission = () => {
    const context = useContext(AppContext);
    const slug = context?.pageProps?.data?.slug || '';

    const {setIsOpen} = useContext(PagePillContext);
    const {callTrackingNumber = {}, pageProps = {}} = useContext(AppContext);

    const [activeForm, setActiveForm] = useState(null);
    const vobFormRef = useRef();
    const smsFormRef = useRef();

    const handleButtonClick = (formType) => {
        setActiveForm(formType);
    };

    useEffect(() => {
        const ref = activeForm === 'vob' ? vobFormRef : smsFormRef;
        ref?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, [activeForm]);

    return (
        <HardcodedPillStyles>
            <div className="container">
                <div className="title">Your Journey To Recovery Starts Now</div>
                <div className="copy">
                    American Addiction Centers offers same-day admission for those seeking
                    help for substance use disorder. Our simple{' '}
                    {slug === 'admissions' ? (
                        <>admissions process</>
                    ) : (
                        <Link href="/admissions" title="Admissions Process">
                            admissions process
                        </Link>
                    )}{' '}
                    takes just minutes. Call today for a free, private consultation.
                </div>
                <div className="button__container">
                    <CallrailButtonStyles>
                        <CallrailButton />
                    </CallrailButtonStyles>
                </div>

                <ul>
                    <li>
                        <div>1</div>
                        <div>Share your story with an admissions navigator 24/7.</div>
                    </li>
                    <li>
                        <div>2</div>
                        <div>Receive a personalized treatment recommendation.</div>
                    </li>
                    <li>
                        <div>3</div>
                        <div>Plan your trip with guidance from us.</div>
                    </li>
                </ul>
                <div className="cta">
                    <div className="cta__header">
                        Not Ready to Talk? Get Started on Your Own Time
                    </div>
                    <div className="cta__buttons">
                        <div
                            className={`button ${activeForm === 'vob' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('vob')}>
                            <IconShield
                                fill="var(--interactive)"
                                width="32"
                                height="32"
                            />
                            <div>Check Your Insurance Coverage</div>
                        </div>
                        <div
                            className={`button ${activeForm === 'sms' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('sms')}>
                            <IconChat fill="var(--interactive)" width="32" height="32" />
                            <div>Text AAC to Discuss Payment Options</div>
                        </div>
                    </div>
                    <div className="cta__forms">
                        <div
                            className={`cta__form ${
                                activeForm === 'vob' ? 'active' : ''
                            }`}
                            ref={vobFormRef}>
                            <VobHeader />
                            <VobAbandonModal
                                handleFinishClick={() => {
                                    setIsOpen(true);
                                    ref?.current?.scrollIntoView({behavior: 'smooth'});
                                }}>
                                <VobApp
                                    type="multi-step"
                                    mentalHealthForm={useMentalHealthVob(
                                        pageProps?.data?.tags,
                                    )}
                                />
                            </VobAbandonModal>
                        </div>
                        <div
                            className={`cta__form ${
                                activeForm === 'sms' ? 'active' : ''
                            }`}
                            ref={smsFormRef}>
                            <SmsFormAbandonModal
                                callTrackingNumber={callTrackingNumber}
                                handleFinishClick={() => {
                                    handleButtonClick('sms');
                                    setIsOpen(true);
                                }}>
                                <AktifyForm />
                            </SmsFormAbandonModal>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    ul {
                        list-style: none;
                        margin-bottom: 40px;
                        padding: 0 0 16px 0;
                        font-weight: bold;
                        font-size: 16px;
                        border-bottom: 1px solid var(--gray-300);
                    }
                    li {
                        display: grid;
                        grid-template-columns: 32px auto;
                        grid-gap: 16px;
                        margin-bottom: 24px;
                    }
                    li > div:first-child {
                        background: var(--primary-300);
                        color: #fff;
                        border-radius: 100%;
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .cta__header {
                        text-align: center;
                        font-weight: bold;
                        margin-bottom: 16px;
                    }
                    .cta__buttons {
                        margin-bottom: 40px;
                    }
                    .button {
                        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
                            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                        border-radius: 8px;
                        margin-bottom: 24px;
                        padding: 16px;
                        text-align: center;
                        cursor: pointer;
                        border: 2px solid #fff;
                    }
                    .button.active {
                        border: 2px solid var(--primary);
                    }
                    .button > div {
                        font-weight: bold;
                        margin-top: 16px;
                    }

                    .cta__form {
                        display: none;
                    }
                    .cta__form.active {
                        display: block;
                    }
                `}
            </style>
        </HardcodedPillStyles>
    );
};
export default SameDayAdmission;

const CallrailButtonStyles = styled.div`
    .button-phone {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 8px;
        margin-bottom: 24px;
    }
`;
