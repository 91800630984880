import 'aac-components/components/Accordion';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import Link from 'next/link';
import Accordion from 'aac-components/components/Accordion';
import styled from 'styled-components';
import React from 'react';
import {hasChildren} from './index';

const FooterMobile = ({menu = []}) => {
    return (
        <div className="container">
            <AccordionStyles>
                {Array.isArray(menu) &&
                    menu.map((section, sectionIndex) => {
                        return (
                            <React.Fragment key={section?.ID}>
                                <Accordion title={section?.title}>
                                    {hasChildren(section) && (
                                        <div>
                                            {section?.child_items?.map(
                                                (secondary_item) => {
                                                    return (
                                                        <React.Fragment
                                                            key={secondary_item?.ID}>
                                                            {secondary_item?.url ===
                                                            '#' ? (
                                                                <div
                                                                    className="secondary-item__title"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: secondary_item?.title,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Link
                                                                    href={
                                                                        secondary_item?.url
                                                                    }
                                                                    legacyBehavior>
                                                                    <a
                                                                        className="secondary-item__title"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: secondary_item?.title,
                                                                        }}
                                                                    />
                                                                </Link>
                                                            )}
                                                            {hasChildren(
                                                                secondary_item,
                                                            ) && (
                                                                <React.Fragment>
                                                                    {secondary_item?.child_items?.map(
                                                                        (
                                                                            tertiary_item,
                                                                        ) => {
                                                                            return (
                                                                                <Link
                                                                                    key={
                                                                                        tertiary_item?.url
                                                                                    }
                                                                                    href={
                                                                                        tertiary_item?.url
                                                                                    }
                                                                                    legacyBehavior>
                                                                                    <a className="tertiary-item__wrapper">
                                                                                        {sectionIndex ===
                                                                                            0 && (
                                                                                            <div className="tertiary-item__icon">
                                                                                                <IconLocation
                                                                                                    width="14"
                                                                                                    height="14"
                                                                                                    fill="var(--gray-100)"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                        <span
                                                                                            className="tertiary-item__title"
                                                                                            title={
                                                                                                tertiary_item?.title
                                                                                            }
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: tertiary_item?.title,
                                                                                            }}
                                                                                        />
                                                                                    </a>
                                                                                </Link>
                                                                            );
                                                                        },
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                },
                                            )}
                                        </div>
                                    )}
                                </Accordion>
                            </React.Fragment>
                        );
                    })}
            </AccordionStyles>
            <style jsx>
                {`
                    .container {
                        padding: 0 15px;
                        margin-bottom: 30px;
                    }
                    .secondary-item__title {
                        font-weight: bold;
                        margin: 0 0 16px 16px;
                        color: var(--gray-100);
                    }
                    a.secondary-item__title {
                        display: block;
                        color: var(--gray-100);
                        font-weight: normal;
                    }
                    .tertiary-item__wrapper {
                        display: flex;
                        align-items: center;
                        margin: 0 0 16px 32px;
                        color: var(--var-100);
                    }
                `}
            </style>
        </div>
    );
};
export default FooterMobile;

const AccordionStyles = styled.div`
    .accordion__title {
        color: #fff !important;
    }
    .accordion__head:before,
    .accordion__head:after {
        background: #fff !important;
    }
    .is-expanded .accordion__body {
        height: fit-content !important;
    }
`;
