import {useRef, useState} from 'react';
import AktifyForm from 'aac-components/components/AktifyForm';
import IconCheckCircle from 'aac-components/components/Icons/Basic/IconCheckCircle';
import SmsFormAbandonModal from 'aac-components/components/AktifyForm/SmsFormAbandonModal';

const AktifyShortcode = ({automationTestingSelector = 'aktify-shortcode'}) => {
    const ref = useRef(null);
    const [showIntroText, setShowIntroText] = useState(true);
    const handleSubmit = () => {
        setShowIntroText(false);
        ref.current.scrollIntoView({behavior: 'smooth'});
    };
    return (
        <div className="container" ref={ref}>
            <div className="form-header">
                <div className="form-header__title">
                    {' '}
                    Prefer texting to talking on the phone? We’ve got you covered 24/7.
                </div>
                {showIntroText && (
                    <>
                        <div className="form-header__sub-title">
                            Instead, sign up for text support to receive:
                        </div>
                        <ul>
                            <li>
                                <IconCheckCircle
                                    width="20"
                                    height="20"
                                    fill="var(--success)"
                                />
                                <span>Resources about addiction and recovery</span>
                            </li>
                            <li>
                                <IconCheckCircle
                                    width="20"
                                    height="20"
                                    fill="var(--success)"
                                />

                                <span>Information about our treatment process</span>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <div className="aktify-form__container">
                <AktifyForm
                    onSubmit={handleSubmit}
                    automationTestingSelector={automationTestingSelector}
                />
            </div>
            <style jsx>
                {`
                    .container {
                        max-width: 475px;
                        border: 1px solid var(--gray-300);
                        border-radius: 8px;
                        padding: 30px 15px;
                        background: #fff;
                        margin: 0 auto;
                    }
                    .form-header__title {
                        font-size: 22px;
                        line-height: 32px;
                        margin-bottom: 14px;
                        text-align: center;
                        font-weight: bold;
                    }
                    .form-header__sub-title {
                        margin-bottom: 16px;
                        text-align: center;
                        font-weight: bold;
                    }
                    .form-header ul {
                        list-style: none;
                        padding: 0;
                        margin-bottom: 40px;
                        max-width: fit-content;
                        margin: 0 auto 24px auto;
                    }
                    .form-header li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 15px;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    .form-header li > span {
                        margin-left: 10px;
                    }
                `}
            </style>
        </div>
    );
};
export default AktifyShortcode;
