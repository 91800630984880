import React, {useState, useContext, useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

import SlideInLeftCta from 'aac-components/components/SlideInLeftCta';
import IconStopwatch from 'aac-components/components/Icons/Misc/IconStopwatch';
import IconUserHeart from 'aac-components/components/Icons/User/IconUserHeart';
import IconHomeCheckEnclosed from 'aac-components/components/Icons/Building/IconHomeCheckEnclosed';
import IconHomeHeartOutline from 'aac-components/components/Icons/Building/IconHomeHeartOutline';
import {isMappableArray} from 'aac-components/utils/helpers';
import styled from 'styled-components';
import url from 'url';
import SameDayAdmission from './HardcodedPillContent/SameDayAdmission';
import {getSegmentNumber} from '../../lib/utils';
import AppContext from '../AppContext';
import MedicalDetox from './HardcodedPillContent/MedicalDetox';
import InsuranceAccepted from './HardcodedPillContent/InsuranceAccepted';
import EvidenceBased from './HardcodedPillContent/EvidenceBased';
import ExpertStaff from './HardcodedPillContent/ExpertStaff';
import OutpatientOptions from './HardcodedPillContent/OutpatientOptions';
import LevelsOfCare from './HardcodedPillContent/LevelsOfCare';
import HeaderPill from 'aac-components/components/HeaderPill';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import GeoTargetDrawerContent from 'aac-components/components/GeoTargetDrawerContent';
import facilityInfo from '../../lib/facility-info.json';
import RelatedOutpatientServices from './HardcodedPillContent/RelatedOutpatientServices';
import {useWpFetch} from '../../lib/hooks/useFetch';

export const PagePillContext = React.createContext();

const PagePills = () => {
    const {
        pageProps = {},
        acfOptions = {},
        sessionStorageState: {geolocation = {}} = {},
        callTrackingNumber = {},
    } = ({} = useContext(AppContext));

    const [isOpen, setIsOpen] = useState(false);
    const [activePill, setActivePill] = useState({type: '', index: 0});

    const contentRef = useRef();

    const facilitySlug =
        Object.keys(facilityInfo).reduce((acc, curr) => {
            if (facilityInfo?.[curr]?.siteKey == geolocation?.facility) {
                acc.push(curr);
            }
            return acc;
        }, [])?.[0] || '';

    // page level customizations
    const {
        data: {
            acf: {
                content_segment = '',
                page_level_pills: {
                    hardcoded_pills: {
                        include_hardcoded_pills = true,
                        custom_pick_hardcoded_pills = false,
                        custom_picked_hardcoded_pills = [],
                    } = {},
                    include_custom_segment_pills = true,
                    custom_pills: {include_custom_pills = false, custom_pills = []} = {},
                } = {},
            } = {},
            slug = '',
        } = {},
    } = pageProps;

    const segmentNumber = getSegmentNumber(content_segment);

    // segment level pills
    const {
        acf: {segment_page_pills: {custom_pills: segment_custom_pills = []} = {}} = {},
    } = acfOptions;
    const segmentLevelPills =
        isMappableArray(segment_custom_pills) &&
        segment_custom_pills?.filter((x) =>
            x?.pill_segments?.includes(segmentNumber?.toString()),
        );

    useEffect(() => {
        // scroll back to top
        contentRef?.current?.scrollIntoView();

        if (typeof window !== 'undefined' && window?.CallTrk) {
            window.CallTrk.swap();
        }
    }, [activePill]);

    const router = useRouter();
    const clearCache = Boolean(router?.query?.clearCache);

    let {data: facilities = []} = useWpFetch(
        `/wordpress/wp/v2/facilities?per_page=50&_fields=title,slug,link,acf,featured_image`,
        clearCache,
    );

    const hardcodedPills = [
        {
            id: 'medical_detox',
            title: 'Medical Detox',
            Content: () => <MedicalDetox />,
            Icon: () => (
                <IconUserHeart width="18" height="18" fill="var(--secondary-300)" />
            ),
            segments: [2, 3, 4, 5],
            excludedSegments: ['info-drug-nodetox'],
        },
        {
            id: 'insurance_accepted',
            title: 'Insurance Accepted',
            Content: () => <InsuranceAccepted />,
            Icon: IconHomeCheckEnclosed,
            segments: [3, 4, 5],
        },
        {
            id: 'same_day_admission',
            title: 'Same Day Admission',
            Content: () => <SameDayAdmission />,
            Icon: IconStopwatch,
            segments: [1, 3, 4, 5],
        },
        {
            id: 'evidence_based',
            title: 'Evidence-Based Care',
            Content: () => <EvidenceBased />,
            Icon: IconHomeCheckEnclosed,
            segments: [1, 2],
        },
        {
            id: 'expert_staff',
            title: 'Expert Staff',
            Content: () => <ExpertStaff />,
            Icon: IconUserHeart,
            segments: [1, 2],
        },
        {
            id: 'outpatient_options',
            title: 'Outpatient Options',
            Content: () => <OutpatientOptions />,
            Icon: IconHomeHeartOutline,
            segments: ['info-drug-nodetox'],
        },
        {
            id: 'levels_of_care',
            title: 'Levels of Care',
            Content: () => <LevelsOfCare />,
            Icon: IconHomeHeartOutline,
            segments: [1, 2, 3],
            excludedSegments: ['info-drug-nodetox'],
        },
        {
            id: 'related_outpatient_services',
            title: 'Related Outpatient Services',
            Content: () => <RelatedOutpatientServices facilities={facilities} />,
            Icon: IconHomeHeartOutline,
            segments: [4],
            excludedSegments: ['info-drug-nodetox'],
            includeOnSpecificPage: true,
        },
    ];

    // hardcoded pills
    const hardcodedPillsArr = custom_pick_hardcoded_pills
        ? hardcodedPills.filter((x) => custom_picked_hardcoded_pills.includes(x?.id))
        : hardcodedPills;

    useEffect(() => {
        setIsOpen(false);
    }, [slug]);

    return (
        <PagePillContext.Provider value={{setIsOpen}}>
            <div id="page-pills">
                <PillStyles>
                    <div className="pills__container">
                        {include_hardcoded_pills &&
                            hardcodedPillsArr.map((pill, index) => {
                                const {
                                    Icon,
                                    segments,
                                    excludedSegments,
                                    includeOnSpecificPage,
                                } = pill;

                                if (
                                    (!segments?.includes(segmentNumber) &&
                                        !segments?.includes(content_segment) &&
                                        !custom_pick_hardcoded_pills) ||
                                    excludedSegments?.includes(content_segment)
                                ) {
                                    return null;
                                } else if (includeOnSpecificPage) {
                                    if (!DisplayRelatedOutpatientServicesPill()) {
                                        return false;
                                    }
                                }
                                return (
                                    <React.Fragment key={pill?.id}>
                                        <HeaderPill
                                            id={pill?.id}
                                            label={pill?.title}
                                            Icon={pill?.Icon}
                                            onClick={() => {
                                                setActivePill({
                                                    type: 'hardcoded',
                                                    index,
                                                });
                                                setIsOpen(true);
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}

                        {include_custom_segment_pills &&
                            isMappableArray(segmentLevelPills) &&
                            segmentLevelPills.map((pill, index) => {
                                return (
                                    <React.Fragment key={pill?.pill_title}>
                                        <HeaderPill
                                            id={`segment_level_pill-${index}`}
                                            label={pill?.pill_title}
                                            onClick={() => {
                                                setActivePill({
                                                    type: 'segment_level',
                                                    index,
                                                });
                                                setIsOpen(true);
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        {include_custom_pills &&
                            isMappableArray(custom_pills) &&
                            custom_pills.map((pill, index) => {
                                return (
                                    <React.Fragment key={pill?.pill_title}>
                                        <HeaderPill
                                            id={`page_level_pill-${index}`}
                                            label={pill?.pill_title}
                                            onClick={() => {
                                                setActivePill({
                                                    type: 'page_level',
                                                    index,
                                                });
                                                setIsOpen(true);
                                            }}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        {geolocation?.facility && (
                            <HeaderPill
                                geolocation={geolocation}
                                Icon={IconLocation}
                                id="geoTarget"
                                label="Get Local Help"
                                onClick={() => {
                                    setActivePill({type: 'geolocation'});
                                    setIsOpen(true);
                                }}
                            />
                        )}
                    </div>
                </PillStyles>

                {/**
                 * For the slide ins, we want to show the segment level and page level content in the DOM for SEO and for the
                 * portals to render within them, but the hardcoded ones don't need to be rendered in the DOM on pageload.
                 */}
                <SlideInLeftCta isOpen={isOpen} handleClose={() => setIsOpen(false)}>
                    <div ref={contentRef} className="pill__content--container">
                        {isMappableArray(custom_pills) &&
                            custom_pills.map((pill, index) => {
                                return (
                                    <div
                                        className={`pill__content ${
                                            activePill?.type === 'page_level' &&
                                            activePill?.index === index
                                                ? 'show'
                                                : ''
                                        }`}
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: pill?.pill_content,
                                        }}
                                    />
                                );
                            })}
                        {isMappableArray(segmentLevelPills) &&
                            segmentLevelPills.map((pill, index) => {
                                return (
                                    <div
                                        className={`pill__content ${
                                            activePill?.type === 'segment_level' &&
                                            activePill?.index === index
                                                ? 'show'
                                                : ''
                                        }`}
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                            __html: pill?.pill_content,
                                        }}
                                    />
                                );
                            })}
                        {isMappableArray(hardcodedPillsArr) &&
                            activePill?.type === 'hardcoded' &&
                            hardcodedPillsArr.map((pill, index) => {
                                const {Content} = pill;
                                if (activePill?.index !== index) return null;
                                return (
                                    <div className={`pill__content.show`} key={index}>
                                        <Content />
                                    </div>
                                );
                            })}
                        {activePill?.type === 'geolocation' && geolocation?.facility && (
                            <>
                                <GeoTargetDrawerContent
                                    geolocation={geolocation}
                                    callTrackingNumber={callTrackingNumber}
                                    secondaryButton={{
                                        copy: 'Take Our Facility Tour',
                                        href: `/treatment-centers/${facilitySlug}/facility-tour`,
                                    }}
                                />
                            </>
                        )}
                    </div>
                </SlideInLeftCta>
            </div>
            <style jsx>
                {`
                    .pills__container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        grid-gap: 8px;
                        flex-wrap: wrap;
                        margin-bottom: 24px;
                    }

                    .pill__content {
                        display: none;
                    }
                    .pill__content.show {
                        display: block;
                    }
                    .pill__content--container {
                        scroll-margin: 40px;
                    }
                `}
            </style>
        </PagePillContext.Provider>
    );
};
export default PagePills;

const PillStyles = styled.div`
    button,
    button#geotarget-tab {
        background: var(--secondary-100);
        color: var(--secondary-300);
        max-width: fit-content;
        padding: 4px 8px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        min-width: fit-content;
        height: 26px;
    }
    button:active {
        background: var(--secondary-200);
        color: var(--secondary-300);
    }
    button#geotarget-tab {
        border: 2px solid var(--secondary-300);
    }
    svg {
        filter: brightness(0) saturate(100%) invert(28%) sepia(88%) saturate(585%)
            hue-rotate(180deg) brightness(97%) contrast(97%);
    }
`;

/*
    function that handles checking if RelatedOutpatientServices pill should
    be displayed on this particular page or not
    return true/false
*/
const DisplayRelatedOutpatientServicesPill = () => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    const relatedOutpatientServicesPaths = [
        '/treatment-centers/recovery-first-hollywood',
        '/treatment-centers/florida',
        '/treatment-centers/florida/miami',
        '/treatment-centers/florida/miami/outpatient',
        '/treatment-centers/river-oaks',
        '/treatment-centers/florida/tampa',
        '/treatment-centers/florida/tampa/outpatient',
        '/treatment-centers/adcare-hospital',
        '/treatment-centers/massachusetts',
        '/treatment-centers/massachusetts/worcester',
        '/treatment-centers/massachusetts/boston',
        '/treatment-centers/oxford',
        '/treatment-centers/mississippi',
        '/treatment-centers/mississippi/jackson',
        '/treatment-centers/desert-hope',
        '/treatment-centers/nevada',
        '/treatment-centers/nevada/las-vegas',
        '/treatment-centers/nevada/henderson',
        '/treatment-centers/nevada/clark-county',
        '/treatment-centers/nevada/spring-valley',
        '/treatment-centers/nevada/enterprise',
        '/treatment-centers/nevada/pahrump',
        '/treatment-centers/adcare-rhode-island',
        '/treatment-centers/rhode-island',
        '/treatment-centers/greenhouse',
        '/treatment-centers/texas',
        '/treatment-centers/texas/arlington',
        '/treatment-centers/texas/irving',
        '/treatment-centers/texas/dallas',
        '/treatment-centers/texas/dallas/outpatient',
        '/treatment-centers/texas/fort-worth',
        '/treatment-centers/texas/plano',
        '/treatment-centers/texas/waco',
    ];

    return relatedOutpatientServicesPaths.some((path) => pathname.includes(path));
};
