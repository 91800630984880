export const getOrganizedMenu = (menuItems) => {
    const addHierachyToMenu = (item = {}, list = []) => {
        const parents = list.filter((i) => i.menu_item_parent === item.ID.toString());
        const children = list.filter((i) => i.menu_item_parent !== item.ID.toString());
        const result = parents.map((i) => addHierachyToMenu(i, children));
        return {child_items: result, ...item};
    };
    const parents = Array.isArray(menuItems)
        ? menuItems.filter((item) => item.menu_item_parent === '0')
        : [];
    const children = Array.isArray(menuItems)
        ? menuItems.filter((item) => item.menu_item_parent !== '0')
        : [];
    const menu = parents.map((item) => addHierachyToMenu(item, children));
    return menu;
};
