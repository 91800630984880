import Link from 'next/link';
import {BREAKPOINT} from '../../lib/styles';

const NavLink = ({url = '', title = ''}) => {
    const isExternalLink = new RegExp(/http(s?)|www/g).test(url);
    return (
        <>
            {isExternalLink ? (
                <a href={url} title={title} target="_blank">
                    {title}
                </a>
            ) : (
                <Link
                    href={url}
                    title={title}
                    style={{color: 'var(--secondary-300)', textDecoration: 'none'}}>
                    {title}
                </Link>
            )}
            <style jsx>
                {`
                    a {
                        text-decoration: none;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        a {
                            color: var(--tertiary);
                        }
                    }
                `}
            </style>
        </>
    );
};
export default NavLink;
