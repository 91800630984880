import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {zIndex} from 'aac-components/utils/helpers';
import NavCta from '../NavCta';

const MainMenu = ({menu, show, setActiveSecondaryMenuId, menuHeight, cta}) => {
    return (
        <div className={`main-menu ${show ? 'show' : ''}`}>
            <ul>
                {Array.isArray(menu) &&
                    menu.map((item) => {
                        const {title, ID} = item;

                        return (
                            <li
                                key={ID}
                                className="main-menu__item"
                                onClick={() => setActiveSecondaryMenuId(ID)}>
                                <span dangerouslySetInnerHTML={{__html: title}} />
                                <IconChevron rotate={'-90'} />
                            </li>
                        );
                    })}
            </ul>
            <NavCta isMain={true} />
            <style jsx>
                {`
                    .main-menu {
                        background: #fff;
                        overflow-y: scroll;
                        transform: translateX(100%);
                        position: relative;
                        height: 0;
                        z-index: ${zIndex('navigationOpen')};
                    }
                    .main-menu.show {
                        width: 100%;
                        height: ${menuHeight}px;
                        transform: translateX(0);
                    }
                    .main-menu ul {
                        padding: 0;
                        margin: 0;
                    }
                    .main-menu__item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px;
                    }
                    .main-menu__item:hover {
                        cursor: pointer;
                        background: var(--interactive-100);
                    }
                    .main-menu::-webkit-scrollbar {
                        width: 5px;
                    }
                    .main-menu::-webkit-scrollbar-track {
                        background: var(--interactive-100);
                        border-radius: 2em;
                    }
                    .main-menu::-webkit-scrollbar-thumb {
                        background: var(--interactive-200);
                        border-radius: 2em;
                    }
                `}
            </style>
        </div>
    );
};
export default MainMenu;
