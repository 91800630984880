const playbookId1 = 370689;
const playbookId2 = 372721;

export const launchChat = () => {
    const randomNumber = getRandomNumber(1, 100);
    //const interactionId = randomNumber >= 50 ? playbookId1 : playbookId2;

    // let driftData = sessionStorage.getItem('drift_chat') || null;
    // const conversationId = JSON.parse(driftData)?.conversationId;

    if (window && window.drift && window.drift.api) {
        // if (conversationId) {
        //     drift.api.goToConversation(Number(conversationId));
        // } else {
        //     drift.api.startInteraction({
        //         interactionId,
        //         replaceActiveConversation: true,
        //     });
        // }

        // for drift reset request from LiftAI team:
        drift.api.startInteraction({
            interactionId: 381917,
            goToConversation: true, //Determines if the playbook should auto-expand the chat when fired. Set this to false if you want the playbook to show a preview message instead of popping open.
        });
    }

    // heap tracking
    if (window && window.heap) {
        window?.heap?.track('Drift Start Chat', {
            ['Interaction ID']: 381917,
        });
    }

    return;
};

export const getRandomNumber = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
