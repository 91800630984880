import styled from 'styled-components';
import {BREAKPOINT} from 'aac-components/lib/styles';

export const InsuranceCoverageTemplateStyles = styled.div`
    :global(#wordpress-content h2) {
        margin: 30px 0 24px 0;
    }
    h1 {
        color: #fff;
        margin-bottom: 24px;
    }
    h2 {
        margin-bottom: 16px;
    }
    hr {
        margin-bottom: 40px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        hr {
            margin-bottom: 80px;
        }
    }

    .section__hero {
        padding: 0;
        margin-bottom: 40px;
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__hero {
            padding: 40px 0;
            margin-bottom: 80px;
        }
    }
    .section__hero--content {
        display: block;
        padding: 0;
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__hero--content {
            display: grid;
            grid-template-columns: 670px auto;
            grid-gap: 80px;
            align-items: flex-start;
        }
    }
    .hero__content--copy {
        color: #fff;
        position: relative;
        padding: 24px 15px 0 15px;
        text-align: left;
        margin-bottom: 0;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .hero__content--copy {
            padding: 0;
            margin-bottom: 24px;
        }
    }
    .section__hero--title {
        position: relative;
    }

    .hero__content {
        background: var(--primary-300);
        padding: 0;
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .hero__content {
            background: transparent;
            padding: 0;
        }
    }
    .hero__content--card {
        padding: 24px 15px 8px 15px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .hero__content--card {
            padding: 0;
        }
    }
    .hero__content--main {
        background: transparent;
        padding: 24px 16px;
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .hero__content--main {
            padding: 0;
        }
    }
    .hero__content--vob {
        background: var(--primary-300);
        padding: 24px 15px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .hero__content--vob {
            padding: 0;
            background: none;
        }
    }
    .section__content {
        padding: 0 15px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__content {
            padding: 0;
        }
    }
    .section__content-tabs {
        padding: 0 15px;
        max-width: 970px;
        margin: 0 auto 40px auto;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__content-tabs {
            padding: 0;
            margin: 0 auto 80px auto;
        }
    }
    .section__facilities {
        text-align: center;
        padding: 0 16px;
        margin-bottom: 40px;
        display: block;
        position: relative;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__facilities {
            padding: 0;
            margin-bottom: 80px;
        }
    }
    .section__facilities--intro {
        max-width: 670px;
        margin: 0 auto 40px auto;
    }
    .section__facilities--cards {
        margin: 0 0 60px 0;
    }
    .section__facilities--image {
        height: 50px;
        width: 300px;
        position: relative;
        margin: 0 auto 24px auto;
    }
    .section__lower-content {
        padding: 0 16px;
        margin-bottom: 80px;
    }
    .section__faqs {
        padding: 0 16px;
        max-width: 770px;
        margin: 0 auto 40px auto;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__faqs {
            padding: 0;
            margin: 0 auto 80px auto;
        }
    }
    .section__next-steps {
        padding: 0 16px;
        margin-bottom: 40px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__next-steps {
            max-width: 770px;
            margin: 0 auto 80px auto;
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 40px;
        }
    }
    .section__related {
        margin-bottom: 40px;
        padding: 0 16px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .section__related {
            margin: 0 auto 80px auto;
            padding: 0;
        }
    }
`;

export const FAQStyles = styled.div`
    .faq__title {
        text-align: left !important;
    }
`;
export const RelatedTopicsStyles = styled.div`
    ul {
        column-count: 1;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        ul {
            column-count: 2 !important;
        }
    }
    li:before {
        content: '•';
        padding-right: 8px;
        font-size: 24px;
        color: var(--interactive);
    }
    .toggle {
        margin-top: 24px;
        text-align: right;
    }
`;

export const BreadcrumbsStyles = styled.div`
    .breadcrumbs {
        color: #fff !important;
        position: relative !important;
    }
    a,
    .breadcrumbs__text {
        color: #fff !important;
    }
`;

export const ContentTabsStyles = styled.div`
    .tabbed-content a.c-callout__button {
        color: #fff;
        font-weight: normal;
    }
    .tabbed-content__content .cta-shortcode__button a,
    .tabbed-content__content .segment-cta__button a {
        color: #fff;
    }
`;

export const BylineStyles = styled.div`
    .c-byline__entry {
        color: #fff !important;
    }
`;
