import Link from 'next/link';
import Image from 'next/image';
import url from 'url';
import {BREAKPOINT} from 'aac-components/lib/styles';

const FacilityCard = ({facility = {}}) => {
    const href = (facility?.link && url?.parse(facility?.link)?.pathname) || '/';
    return (
        <Link href={href} style={{textDecoration: 'none'}}>
            <div className="fac__card">
                <div className="fac__card--img">
                    <Image
                        src={
                            facility?.featured_image?.[2] || '/static/aac_logo_white.png'
                        }
                        fill
                        style={{
                            objectFit: 'cover',
                        }}
                        sizes="100px"
                        alt={facility?.title?.rendered}
                    />
                </div>
                <div className="fac__card--info">
                    <div
                        className="fac__card--title"
                        dangerouslySetInnerHTML={{
                            __html: facility?.title?.rendered,
                        }}
                    />
                    <div
                        className="fac__card--cities"
                        dangerouslySetInnerHTML={{
                            __html: facility?.acf?.aac_facility_cities,
                        }}
                    />
                </div>
            </div>
            <style jsx>
                {`
                    .fac__card {
                        display: grid;
                        grid-template-columns: 100px auto;
                        grid-gap: 16px;
                    }
                    .fac__card:hover .fac__card--img {
                        box-shadow: var(--box-shadow);
                        transition: all 0.5s;
                    }
                    .fac__card--title {
                        font-weight: bold;
                        color: var(--gray-500);
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    .fac__card--cities {
                        color: var(--gray-400);
                        font-size: 14px;
                    }
                    .fac__card--img {
                        width: 100px;
                        height: 100px;
                        border-radius: 8px;
                        position: relative;
                        overflow: hidden;
                        background: var(--primary);
                    }
                `}
            </style>
        </Link>
    );
};
export default FacilityCard;
