import Button from 'aac-components/components/Button';
import CallrailButton from '../CallrailButton';
import AcfTooltip from '../Tooltips/AcfTooltip';
import Image from 'next/image';

const AdmissionsContent = () => {
    return (
        <div className="container">
            <div className="logo">
                <Image
                    src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-lockup-horizontal-color-v2.svg"
                    width={0}
                    height={0}
                    alt="American Addiction Centers"
                    style={{width: '100%', height: '48px'}}
                />
            </div>
            <div className="title">Our Admissions Process</div>
            <div className="subtitle">
                 Don't wait. Take the first step today. Our team is here for you every
                step of the way.
            </div>
            <div>
                <div className="step">
                    <div className="step__title">Step 1: Getting to Know You</div>
                    <div className="step__copy">
                        Many of our navigators are in recovery, so they can truly
                        empathize with what you are going through.
                    </div>
                </div>
                <div className="step">
                    <div className="step__title">Step 2: Complete Pre-Screening</div>
                    <div className="step__copy">
                        During your pre-screening, your navigator will develop an
                        understanding of your clinical needs.
                    </div>
                </div>
                <div className="step">
                    <div className="step__title">Step 3: Customized Treatment Plan</div>
                    <div className="step__copy">
                        We’ll provide you a determination of which AAC facility is the
                        best option for you. 
                    </div>
                </div>
                <div className="step">
                    <div className="step__title">
                        Step 4: Travel to Treatment Location
                    </div>
                    <div className="step__copy">
                        We'll share a packing list, travel schedule, and offer any
                        assistance for a smooth journey to treatment.
                    </div>
                </div>
                <div className="step">
                    <div className="step__title">
                        Step 5: Arrive for Life-Changing Care
                    </div>
                    <div className="step__copy">
                        Upon arrival, we will lead you through a final medical screening
                        and help you settle in for treatment.
                    </div>
                </div>
            </div>
            <div className="cta">
                <div className="cta__title">We’re available 24/7</div>
                <div className="cta__copy">
                    Call us to discuss how we can help you or your loved one find
                    recovery.
                </div>
                <div className="cta__buttons">
                    <CallrailButton showTooltip={false} style={{width: '100%'}} />
                    <Button
                        theme="invert"
                        href="/verify-insurance"
                        style={{width: '100%'}}>
                        Check My Insurance
                    </Button>
                    <AcfTooltip />
                </div>
            </div>
            <style jsx>
                {`
                    .container {
                        overflow-y: auto;
                    }
                    .logo {
                        margin-bottom: 16px;
                    }
                    .title {
                        font-size: 24px;
                        margin-bottom: 16px;
                        font-weight: bold;
                        text-align: center;
                    }
                    .subtitle {
                        margin-bottom: 40px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                        max-width: 320px;
                        margin: 0 auto 40px auto;
                    }
                    .step {
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid var(--gray-200);
                    }
                    .step:last-child {
                        border-bottom: none;
                    }
                    .step__title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 8px;
                    }
                    .step__copy {
                        font-size: 14px;
                        color: var(--gray-400);
                    }
                    .cta {
                        background: var(--interactive-100);
                        border-radius: 8px;
                        padding: 24px;
                        text-align: center;
                        border: 1px solid var(--interactive-300);
                    }
                    .cta__title {
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 16px;
                    }
                    .cta__copy {
                        font-size: 14px;
                        line-height: 20px;
                        max-width: 280px;
                        margin: 0 auto 16px auto;
                    }
                    .cta__buttons {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                    }
                `}
            </style>
        </div>
    );
};
export default AdmissionsContent;
