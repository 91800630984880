import {useRouter} from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import url from 'url';
import styled from 'styled-components';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import Button from 'aac-components/components/Button';
import HardcodedPillStyles from './HardcodedPillStyles';
import CallrailButton from '../../CallrailButton';
import AcfTooltip from '../../Tooltips/AcfTooltip';
import {BREAKPOINT} from '../../../lib/styles';

const RelatedOutpatientServices = ({facilities}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    return <div>{GetOutpatientFacility({pathname, facilities})}</div>;
};
export default RelatedOutpatientServices;

const GetOutpatientFacility = ({pathname, facilities}) => {
    // double outpatient facilities for flordia state page
    if (pathname === '/treatment-centers/florida') {
        const firstOutpatientSlug = 'outpatient-hollywood';
        const secondOutpatientSlug = 'outpatient-riverview';
        const firstFaviCon =
            '/static/facility-favicon-svgs/recovery-first-treatment-favicon-svg.svg';

        const secondFaviCon =
            '/static/facility-favicon-svgs/river-oaks-treatment-favicon-svg.svg';

        const firstFacilityData = facilities.find(
            (item) => item.slug === firstOutpatientSlug,
        );
        const secondFacilityData = facilities.find(
            (item) => item.slug === secondOutpatientSlug,
        );

        return DoubleRelatedOutpatientServicesContent(
            firstFacilityData,
            firstFaviCon,
            secondFaviCon,
            secondFacilityData,
        );
    }
    //outpatient-hollywood
    else if (
        pathname.includes('/treatment-centers/recovery-first-hollywood') ||
        pathname.includes('/treatment-centers/florida/miami') ||
        pathname.includes('/treatment-centers/florida/miami/outpatient')
    ) {
        const outpatientSlug = 'outpatient-hollywood';
        const faviCon =
            '/static/facility-favicon-svgs/recovery-first-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-riverview
    else if (
        pathname.includes('/treatment-centers/river-oaks') ||
        pathname.includes('/treatment-centers/florida/tampa') ||
        pathname.includes('/treatment-centers/florida/tampa/outpatient')
    ) {
        const outpatientSlug = 'outpatient-riverview';
        const faviCon =
            '/static/facility-favicon-svgs/river-oaks-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-worcester
    else if (
        pathname.includes('/treatment-centers/adcare-hospital') ||
        pathname.includes('/treatment-centers/massachusetts') ||
        pathname.includes('/treatment-centers/massachusetts/worcester') ||
        pathname.includes('/treatment-centers/massachusetts/boston')
    ) {
        const outpatientSlug = 'outpatient-worcester';
        const faviCon = '/static/facility-favicon-svgs/adcare-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-oxford
    else if (
        pathname.includes('/treatment-centers/oxford') ||
        pathname.includes('/treatment-centers/mississippi') ||
        pathname.includes('/treatment-centers/mississippi/jackson')
    ) {
        const outpatientSlug = 'outpatient-oxford';
        const faviCon = '/static/facility-favicon-svgs/oxford-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-las-vegas
    else if (
        pathname.includes('/treatment-centers/desert-hope') ||
        pathname.includes('/treatment-centers/nevada') ||
        pathname.includes('/treatment-centers/nevada/las-vegas') ||
        pathname.includes('/treatment-centers/nevada/henderson') ||
        pathname.includes('/treatment-centers/nevada/clark-county') ||
        pathname.includes('/treatment-centers/nevada/spring-valley') ||
        pathname.includes('/treatment-centers/nevada/enterprise') ||
        pathname.includes('/treatment-centers/nevada/pahrump')
    ) {
        const outpatientSlug = 'outpatient-las-vegas';
        const faviCon =
            '/static/facility-favicon-svgs/desert-hope-treatment-favicon-svg.svg';
        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-warwick
    else if (
        pathname.includes('/treatment-centers/adcare-rhode-island') ||
        pathname.includes('/treatment-centers/rhode-island')
    ) {
        const outpatientSlug = 'outpatient-warwick';

        const faviCon = '/static/facility-favicon-svgs/adcare-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
    //outpatient-arlington
    else if (
        pathname.includes('/treatment-centers/greenhouse') ||
        pathname.includes('/treatment-centers/texas') ||
        pathname.includes('/treatment-centers/texas/arlington') ||
        pathname.includes('/treatment-centers/texas/irving') ||
        pathname.includes('/treatment-centers/texas/dallas') ||
        pathname.includes('/treatment-centers/texas/dallas/outpatient') ||
        pathname.includes('/treatment-centers/texas/fort-worth') ||
        pathname.includes('/treatment-centers/texas/plano') ||
        pathname.includes('/treatment-centers/texas/waco')
    ) {
        const outpatientSlug = 'outpatient-arlington';
        const faviCon =
            '/static/facility-favicon-svgs/greenhouse-treatment-favicon-svg.svg';

        const facilityData = facilities.find((item) => item.slug === outpatientSlug);

        return RelatedOutpatientServicesContent(facilityData, faviCon);
    }
};

const BackgroundImage = styled.div`
    background-image: url(${(props) => props.imageUrl});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 170px;
    height: 170px;
    top: ${(props) => (props.secondFavicon ? '780px' : '260px')};
    left: 215px;
    z-index: -1;

    @media screen and (min-width: ${BREAKPOINT}) {
        left: 295px;
    }
`;

const RelatedOutpatientServicesContent = (facilityData, faviCon) => {
    return (
        <div>
            <HardcodedPillStyles>
                {!facilityData || !facilityData.featured_image ? (
                    <div>{/* displays nothing while content loads */}Loading...</div>
                ) : (
                    <div className="related-outpatient-services__container">
                        <BackgroundImage imageUrl={faviCon} />
                        <div className="related-outpatient-services__facility-image">
                            <Image
                                src={facilityData?.featured_image[1]}
                                alt="American Addiction Centers logo"
                                width={300}
                                height={200}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                sizes="300px"
                            />
                        </div>
                        <div className="related-outpatient-services__facility-name">
                            {facilityData?.title?.rendered}
                        </div>
                        <div className="related-outpatient-services__facility-location">
                            <div className="related-outpatient-services__icon-location">
                                <IconLocation width="24" height="24" fill="black" />
                            </div>
                            <div className="related-outpatient-services__location-name">
                                {GetCityState(facilityData)}
                            </div>
                        </div>
                        <div className="related-outpatient-services__facility-excerpt">
                            {GetFacilityExcerpts(facilityData)}
                        </div>
                        <div className="related-outpatient-services__facility-button">
                            <Button
                                href={facilityData?.link}
                                style={{minWidth: '100%', fontWeight: 'normal'}}>
                                <div className="button__copy">
                                    See Outpatient Facility
                                </div>
                            </Button>
                        </div>
                        <div className="related-outpatient-services__callrail-button">
                            <CallrailButton
                                style={{margin: '0 auto', width: '100%'}}
                                showTooltip={false}
                                theme={'invert'}
                            />
                            <div className="related-outpatient-services__tooltip">
                                <AcfTooltip
                                    iconFill="var(--interactive)"
                                    type="phone"
                                    color={'primary'}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <style jsx>{`
                    .related-outpatient-services__container {
                        width: 342px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .related-outpatient-services__container {
                            width: 422px;
                        }
                    }
                    .related-outpatient-services__facility-image {
                        width: 100%;
                        height: 180px;
                        margin-top: 15px;
                        margin-bottom: 25px;
                    }
                    .related-outpatient-services__facility-name {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 10px;
                    }
                    .related-outpatient-services__facility-location {
                        display: flex;
                        height: 28px;
                        gap: 8px;
                        margin-bottom: 25px;
                    }
                    .related-outpatient-services__location-name {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    .related-outpatient-services__facility-excerpt {
                        margin-bottom: 25px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .related-outpatient-services__facility-button {
                        margin-bottom: 15px;
                    }
                    .related-outpatient-services__tooltip {
                        margin-top: 8px;
                    }
                `}</style>
            </HardcodedPillStyles>
        </div>
    );
};

/*
    displays the double florida outpatient data
*/
const DoubleRelatedOutpatientServicesContent = (
    firstFacilityData,
    firstFaviCon,
    secondFaviCon,
    secondFacilityData,
) => {
    return (
        <div>
            <HardcodedPillStyles>
                {!firstFacilityData || !firstFacilityData.featured_image ? (
                    <div>{/* displays nothing while content loads */}Loading...</div>
                ) : (
                    <div className="related-outpatient-services__container">
                        <BackgroundImage imageUrl={firstFaviCon} />
                        <div className="related-outpatient-services__facility-image">
                            <Image
                                src={firstFacilityData?.featured_image[1]}
                                alt="American Addiction Centers logo"
                                width={300}
                                height={200}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                sizes="300px"
                            />
                        </div>
                        <div className="related-outpatient-services__facility-name">
                            {firstFacilityData?.title?.rendered}
                        </div>
                        <div className="related-outpatient-services__facility-location">
                            <div className="related-outpatient-services__icon-location">
                                <IconLocation width="24" height="24" fill="black" />
                            </div>
                            <div className="related-outpatient-services__location-name">
                                {GetCityState(firstFacilityData)}
                            </div>
                        </div>
                        <div className="related-outpatient-services__facility-excerpt">
                            {GetFacilityExcerpts(firstFacilityData)}
                        </div>
                        <div className="related-outpatient-services__facility-first-button">
                            <Button
                                href={firstFacilityData?.link}
                                style={{minWidth: '100%', fontWeight: 'normal'}}>
                                <div className="button__copy">
                                    See Outpatient Facility
                                </div>
                            </Button>
                        </div>
                        <BackgroundImage imageUrl={secondFaviCon} secondFavicon={true} />
                        <div className="related-outpatient-services__facility-image">
                            <Image
                                src={secondFacilityData?.featured_image[1]}
                                alt="American Addiction Centers logo"
                                width={300}
                                height={200}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                sizes="300px"
                            />
                        </div>
                        <div className="related-outpatient-services__facility-name">
                            {secondFacilityData?.title?.rendered}
                        </div>
                        <div className="related-outpatient-services__facility-location">
                            <div className="related-outpatient-services__icon-location">
                                <IconLocation width="24" height="24" fill="black" />
                            </div>
                            <div className="related-outpatient-services__location-name">
                                {GetCityState(secondFacilityData)}
                            </div>
                        </div>
                        <div className="related-outpatient-services__facility-excerpt">
                            {GetFacilityExcerpts(secondFacilityData)}
                        </div>
                        <div className="related-outpatient-services__facility-button">
                            <Button
                                href={secondFacilityData?.link}
                                style={{minWidth: '100%', fontWeight: 'normal'}}>
                                <div className="button__copy">
                                    See Outpatient Facility
                                </div>
                            </Button>
                        </div>
                        <div className="related-outpatient-services__callrail-button">
                            <CallrailButton
                                style={{margin: '0 auto', width: '100%'}}
                                showTooltip={false}
                                theme={'invert'}
                            />
                            <div className="related-outpatient-services__tooltip">
                                <AcfTooltip
                                    iconFill="var(--interactive)"
                                    type="phone"
                                    color={'primary'}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <style jsx>{`
                    .related-outpatient-services__container {
                        width: 342px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .related-outpatient-services__container {
                            width: 422px;
                        }
                    }
                    .related-outpatient-services__facility-image {
                        width: 100%;
                        height: 180px;
                        margin-top: 15px;
                        margin-bottom: 25px;
                    }
                    .related-outpatient-services__facility-name {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 10px;
                    }
                    .related-outpatient-services__facility-location {
                        display: flex;
                        height: 28px;
                        gap: 8px;
                        margin-bottom: 25px;
                    }
                    .related-outpatient-services__location-name {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    .related-outpatient-services__facility-excerpt {
                        margin-bottom: 25px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    .related-outpatient-services__facility-first-button {
                        margin-bottom: 35px;
                    }
                    .related-outpatient-services__facility-button {
                        margin-bottom: 15px;
                    }
                    .related-outpatient-services__tooltip {
                        margin-top: 8px;
                    }
                `}</style>
            </HardcodedPillStyles>
        </div>
    );
};

/*
    holds all the different facility excerpts. returns appropriate data based off slug
*/
const GetFacilityExcerpts = (facilityData) => {
    const excerpts = {
        'outpatient-hollywood': (
            <>
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                offers individualized care in a flexible format, allowing you to stay
                focused on recovery. Continue building a healthier future with our
                tailored outpatient services.
            </>
        ),
        'outpatient-riverview': (
            <>
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                provides comprehensive, adaptable treatment, helping you continue your
                recovery while managing your daily life. Access the support you need
                through our flexible outpatient services.
            </>
        ),
        'outpatient-worcester': (
            <>
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                in Worcester provides convenient, compassionate care to help you sustain
                your recovery. Stay on track with customized outpatient services designed
                around your needs.
            </>
        ),
        'outpatient-warwick': (
            <>
                Find flexible, expert care at{' '}
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                in Warwick, offering personalized treatment to fit your life. Continue
                your recovery journey with our tailored outpatient services.
            </>
        ),
        'outpatient-oxford': (
            <>
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                in Oxford offers tailored treatment to support your ongoing recovery.
                Experience flexible outpatient care that fits into your everyday routine.
            </>
        ),
        'outpatient-las-vegas': (
            <>
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                delivers personalized treatment in a flexible setting, allowing you to
                focus on recovery while balancing daily life. Continue your path to
                wellness with our expert outpatient care.
            </>
        ),
        'outpatient-arlington': (
            <>
                Experience flexible, personalized care at{' '}
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                , supporting your recovery while you maintain daily life. Continue your
                journey to lasting wellness with expert outpatient services tailored to
                your needs.
            </>
        ),
        'outpatient-southaven': (
            <>
                At{' '}
                <Link
                    href={facilityData?.link}
                    title={facilityData?.title?.rendered}
                    style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        textDecoration: 'underline',
                        color: 'var(--interactive)',
                        cursor: 'pointer',
                    }}>
                    {facilityData?.title?.rendered}
                </Link>{' '}
                in Southaven, receive dedicated, individualized care that works with your
                lifestyle. Maintain progress in recovery through our flexible outpatient
                services.
            </>
        ),
    };
    return excerpts[facilityData?.slug] || null;
};

/*
    function that returns only the city and state abbreviation from acf_facility_address
*/
const GetCityState = (facilityData) => {
    const commaIndex = facilityData?.acf?.aac_facility_address.lastIndexOf(',');
    const spaceBeforeComma = facilityData?.acf?.aac_facility_address.lastIndexOf(
        ' ',
        commaIndex - 1,
    );
    const city = facilityData?.acf?.aac_facility_address
        .substring(spaceBeforeComma + 1, commaIndex)
        .trim();
    const state = facilityData?.acf?.aac_facility_address
        .substring(commaIndex + 2, commaIndex + 4)
        .trim();

    return `${city}, ${state}`;
};
