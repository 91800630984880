import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import VobApp from 'aac-components/components/VobApp';
import {useContext} from 'react';
import {PagePillContext} from '../index';
import VobHeader from './VobHeader';
import AppContext from '../../AppContext';
import {useMentalHealthVob} from '../../../lib/utils';

const InsuranceAccepted = () => {
    const {setIsOpen} = useContext(PagePillContext);

    const {pageProps = {}} = useContext(AppContext);

    return (
        <div>
            <VobHeader />
            <div className="vob__container">
                <VobAbandonModal handleFinishClick={() => setIsOpen(true)}>
                    <VobApp
                        type="multi-step"
                        mentalHealthForm={useMentalHealthVob(pageProps?.data?.tags)}
                    />
                </VobAbandonModal>
            </div>
        </div>
    );
};
export default InsuranceAccepted;
