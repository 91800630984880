import {zIndex} from 'aac-components/utils/helpers';
import Link from 'next/link';
import styled from 'styled-components';
import CallrailButton from '../../CallrailButton';

const GetHelpDesktopCta = ({subNavOffset}) => {
    return (
        <div className="get-help">
            <div>
                <div className="get-help__title">Admissions</div>
                <div className="get-help__copy">
                    Recovery starts now. Start your admission process online today.
                </div>
                <LinkStyles>
                    <Link href="/admissions" title="Start Admissions">
                        Start Admissions
                    </Link>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Paying for Treatment</div>
                <div className="get-help__copy">
                    Treatment is an investment in your future. You have options.
                </div>
                <LinkStyles>
                    <Link href="/payment-options" title="Insurance & Payment Options">
                        Insurance & Payment Options
                    </Link>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Contact Us</div>
                <div className="get-help__copy">
                    Get in contact <br />
                    with us online.
                </div>
                <LinkStyles>
                    <Link href="/contact-us" title="Contact Us">
                        Contact Us
                    </Link>
                </LinkStyles>
            </div>
            <div>
                <div className="get-help__title">Need Help?</div>
                <div className="get-help__copy">Your recovery can start today!</div>
                <CallrailButton style={{margin: '0 auto'}} />
            </div>
            <style jsx>{`
                .get-help {
                    background: #fff;
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    position: absolute;
                    width: 100%;
                    z-index: ${zIndex('navigation')};
                    max-width: 1170px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    height: auto;
                    top: ${subNavOffset}px;
                    overflow: hidden;
                    border-radius: 0 0 8px 8px;
                    border-right: 1px solid var(--gray-300);
                    border-bottom: 1px solid var(--gray-300);
                    border-left: 1px solid var(--gray-300);
                    padding: 40px 24px;
                    text-align: center;
                }

                .get-help__title {
                    font-size: 18px;
                    margin-bottom: 24px;
                    color: var(--primary-500);
                    font-weight: bold;
                }
                .get-help__copy {
                    margin-bottom: 24px;
                    line-height: 24px;
                }
            `}</style>
        </div>
    );
};
export default GetHelpDesktopCta;

const LinkStyles = styled.div`
    a {
        font-weight: bold;
        text-decoration: none;
    }
`;
