import {useContext} from 'react';
import Image from 'next/image';
import Button from 'aac-components/components/Button';
import CallrailButton from '../CallrailButton';
import AppContext from '../AppContext';
import styled from 'styled-components';

const NavCta = ({
    isMain = false,
    activeSecondaryMenuIndex,
    menuCta,
    showImage = true,
    showCopy = true,
}) => {
    const {isMobile = true, acfOptions: {acf: {nav_ctas} = {}} = {}} =
        useContext(AppContext);

    const cta = menuCta
        ? menuCta
        : isMain
        ? nav_ctas?.main_menu_cta
        : nav_ctas?.cta?.[activeSecondaryMenuIndex] || null;

    if (!cta) return null;

    const {
        title = '',
        copy = '',
        use_callrail_button = true,
        button_text = '',
        button_href = '',
        image = '',
    } = cta;

    showImage = showImage ? image && (!isMobile || isMain) : false;

    return (
        <div id="nav-cta" className="nav-cta">
            {showImage && (
                <div className="nav-cta__image">
                    <Image
                        src={image}
                        fill
                        style={{objectFit: 'cover'}}
                        alt="American Addiction Centers"
                        sizes="(max-width: 400px) 100vw"
                    />
                </div>
            )}
            {title && (
                <div
                    className="nav-cta__title"
                    dangerouslySetInnerHTML={{__html: title}}
                />
            )}
            {showCopy ? (
                copy && (
                    <div
                        className="nav-cta__copy"
                        dangerouslySetInnerHTML={{__html: copy}}
                    />
                )
            ) : (
                <></>
            )}
            {use_callrail_button ? (
                <ButtonStyles>
                    <CallrailButton />
                </ButtonStyles>
            ) : (
                <ButtonStyles>
                    <Button href={button_href}>{button_text}</Button>
                </ButtonStyles>
            )}
            <style jsx>
                {`
                    .nav-cta {
                        padding: 16px 0 0 16px;
                    }
                    .nav-cta__title {
                        margin-bottom: 16px;
                        font-weight: bold;
                        text-align: center;
                    }
                    .nav-cta__copy {
                        margin-bottom: 16px;
                        height: 44px;
                        text-align: center;
                        font-weight: bold;
                        font-size: 15px;
                        text-align: center;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        white-space: normal;
                    }
                    .nav-cta__image {
                        position: relative;
                        width: 100%;
                        height: 160px;
                        border-radius: 8px;
                        overflow: hidden;
                        margin-bottom: 16px;
                    }
                `}
            </style>
        </div>
    );
};
export default NavCta;

const ButtonStyles = styled.div`
    a {
        min-width: 100%;
        font-weight: normal;
    }
    .button-phone {
        min-width: 100%;
        font-weight: normal;
    }
    .button-phone button {
        font-weight: normal;
    }
`;
