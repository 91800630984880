import Accordion from 'aac-components/components/Accordion';
import styled from 'styled-components';
import NavLink from '../NavLink';
import NavCta from '../NavCta';
import {isMappableArray, zIndex} from 'aac-components/utils/helpers';
import SiteSearch from '../../SiteSearch';
import {SiteSearchStyles} from '../NavDrawer';
import facilityInfo from '../../../lib/facility-info.json';
import Image from 'next/image';
import Link from 'next/link';
import {useEffect, useContext} from 'react';
import React from 'react';
import AppContext from '../../AppContext';

const SubMenu = ({menu = {}, show = false, menuHeight, activeSecondaryMenuIndex}) => {
    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            window?.CallTrk &&
            activeSecondaryMenuIndex > 0
        ) {
            window.CallTrk.swap();
        }
    }, [activeSecondaryMenuIndex]);

    let uniqueFavicons = new Set();
    const {
        sessionStorageState: {
            geolocation: {facility: geolocationSiteKey = '', stateName: state = ''} = {},
        } = {},
    } = ({} = useContext(AppContext));

    return (
        <div className={`sub-menu ${show ? 'show' : ''}`}>
            <div className="nav-cta__container">
                <NavCta activeSecondaryMenuIndex={activeSecondaryMenuIndex} />
            </div>
            {menu?.[activeSecondaryMenuIndex]?.title === 'Our Locations' && (
                <div className="secondary-nav-desktop__outpatient">
                    <Link
                        href="/treatment-centers/outpatient-rehab"
                        title="View Outpatient Locations"
                        style={{
                            display: 'block',
                            textDecoration: 'none',
                            color: 'var(--interactive-300)',
                        }}>
                        View Outpatient Locations
                    </Link>
                </div>
            )}
            {menu?.[activeSecondaryMenuIndex]?.title == 'Search' && (
                <div className="sub-menu__search">
                    <SiteSearchStyles>
                        <SiteSearch />
                    </SiteSearchStyles>
                </div>
            )}

            <div className="sub-menu__accordions">
                {isMappableArray(menu) &&
                    menu?.map((menuSection, sectionIndex) => {
                        const {child_items} = menuSection;
                        return (
                            <div
                                className={`sub-menu__link-bank ${
                                    activeSecondaryMenuIndex === sectionIndex
                                        ? 'show'
                                        : ''
                                }`}
                                key={menuSection?.ID}>
                                {isMappableArray(child_items) &&
                                    child_items.map((item, index) => {
                                        const {
                                            title,
                                            ID,
                                            child_items: linkItems = [],
                                        } = item;
                                        const showClosest =
                                            geolocationSiteKey && title.includes(state);

                                        return (
                                            <div key={ID}>
                                                {menuSection?.title ===
                                                'Our Locations' ? (
                                                    <div
                                                        className={`sub-menu__region-container ${
                                                            showClosest
                                                                ? 'closest-to-you'
                                                                : ''
                                                        }`}>
                                                        {showClosest && (
                                                            <div className="closest-to-you__text">
                                                                Closest to You
                                                            </div>
                                                        )}
                                                        <div
                                                            className={`${
                                                                showClosest
                                                                    ? 'show-closest'
                                                                    : ''
                                                            }`}>
                                                            <div className="sub-menu__location-container">
                                                                <div
                                                                    className="sub-menu__location--title"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: title,
                                                                    }}
                                                                />
                                                                {Array.isArray(
                                                                    linkItems,
                                                                ) &&
                                                                    linkItems.map(
                                                                        (link, index) => {
                                                                            const locationSlug =
                                                                                link?.url &&
                                                                                link?.url.replace(
                                                                                    /\/treatment-centers\//g,
                                                                                    '',
                                                                                );
                                                                            const favicon =
                                                                                facilityInfo?.[
                                                                                    locationSlug
                                                                                ]
                                                                                    ?.favicon;

                                                                            // prevent rendering duplicate favicons
                                                                            if (
                                                                                favicon &&
                                                                                !uniqueFavicons.has(
                                                                                    favicon,
                                                                                )
                                                                            ) {
                                                                                uniqueFavicons.add(
                                                                                    favicon,
                                                                                );

                                                                                return (
                                                                                    <div
                                                                                        className="sub-menu__location--favicon"
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        <Image
                                                                                            src={
                                                                                                favicon
                                                                                            }
                                                                                            width={
                                                                                                24
                                                                                            }
                                                                                            height={
                                                                                                24
                                                                                            }
                                                                                            sizes="50vw"
                                                                                            alt="American Addiction Centers"
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null; // skip rendering if favicon is a duplicate
                                                                        },
                                                                    )}
                                                            </div>
                                                            <ul>
                                                                {linkItems.map(
                                                                    (link, index) => {
                                                                        return (
                                                                            <li
                                                                                key={`${link?.url}-${index}`}>
                                                                                <Link
                                                                                    href={
                                                                                        link?.url
                                                                                    }
                                                                                    title={
                                                                                        link?.title
                                                                                    }
                                                                                    style={{
                                                                                        color: 'var(--secondary-300)',
                                                                                        textDecoration:
                                                                                            'none',
                                                                                    }}>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: link?.title,
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                            </li>
                                                                        );
                                                                    },
                                                                )}
                                                            </ul>{' '}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <AccordionStyles>
                                                        <Accordion
                                                            title={title}
                                                            expand={index === 0}>
                                                            <ul>
                                                                {linkItems.map((link) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                link?.ID
                                                                            }>
                                                                            <NavLink
                                                                                url={
                                                                                    link?.url
                                                                                }
                                                                                title={
                                                                                    link?.title
                                                                                }
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </Accordion>
                                                    </AccordionStyles>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
            </div>
            <style jsx>
                {`
                    .sub-menu {
                        background: #fff;
                        overflow-y: auto;
                        transform: translateX(100%);
                        position: relative;
                        z-index: ${zIndex('navigationOpen')};
                        height: ${menuHeight}px;
                    }
                    .sub-menu.show {
                        width: 100%;
                        transform: translateX(0);
                    }

                    .sub-menu__accordions {
                        padding: 16px;
                    }
                    .sub-menu__link-bank {
                        display: none;
                    }
                    .sub-menu__link-bank.show {
                        display: block;
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }
                    li {
                        margin-bottom: 16px;
                    }
                    a {
                        color: var(--interactive);
                        text-decoration: none;
                    }
                    .sub-menu::-webkit-scrollbar {
                        width: 5px;
                    }
                    .sub-menu::-webkit-scrollbar-track {
                        background: var(--interactive-100);
                        border-radius: 2em;
                    }
                    .sub-menu::-webkit-scrollbar-thumb {
                        background: var(--interactive-200);
                        border-radius: 2em;
                    }
                    .sub-menu__search {
                        padding: 16px;
                    }
                    .sub-menu__location--title {
                        font-weight: bold;
                        margin: 24px 0;
                        padding-bottom: 8px;
                    }
                    .sub-menu__location--image {
                        position: relative;
                        display: block;
                        height: 38px;
                        max-width: fit-content;
                    }
                    .sub-menu__location--link {
                        font-size: 14px;
                        display: grid;
                        grid-template-columns: 40px auto;
                        grid-gap: 8px;
                        align-items: center;
                        color: var(--gray-500);
                    }
                    .secondary-nav-desktop__outpatient {
                        text-align: center;
                        margin-top: 16px;
                        font-weight: 700;
                    }
                    .sub-menu__location-container {
                        display: flex;
                        border-bottom: 1px solid var(--gray-200);
                        height: 60px;
                        margin-bottom: 10px;
                    }
                    .sub-menu__location--favicon {
                        margin: 24px 0;
                        padding-bottom: 8px;
                        padding-left: 10px;
                    }
                    .closest-to-you {
                        border: 3px solid var(--warning);
                        border-radius: 8px;
                    }
                    .closest-to-you__text {
                        background: #ffad0d;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: bold;
                        width: 120px;
                        height: 24px;
                        text-align: center;
                        padding: 4px 16px;
                        border-top-left-radius: 5px;
                        border-bottom-right-radius: 8px;
                    }
                    .show-closest {
                        padding: 0 16px;
                    }
                    .show-closest > .sub-menu__location-container {
                        border-bottom: none;
                    }
                    .nav-cta__container {
                        padding-right: 16px;
                    }
                `}
            </style>
        </div>
    );
};
export default SubMenu;

const AccordionStyles = styled.div`
    .accordion {
        border-bottom: none !important;
    }
    .accordion__title {
        color: var(--gray-500) !important;
        font-weight: normal !important;
    }
    .is-expanded .accordion__title {
        font-weight: bold !important;
    }
`;
