import {useEffect} from 'react';
import {debounce} from 'throttle-debounce';
import useStateIfMounted from '../../../../lib/hooks/useStateIfMounted';
import Image from 'next/image';
import Link from 'next/link';
import MobileNavTriggers from '../MobileNavTriggers';
import {aacLogoHorizontal} from '../../../../lib/globals';

const MobileNavHeader = () => {
    const [scrollTop, setScrollTop] = useStateIfMounted(0);

    // handle showing "Next Steps" in nav when user scrolls down
    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', debounce(100, onScroll));
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    return (
        <div className="mobile-nav__header">
            <div>
                <Link href="/" title="Home">
                    <div className="mobile-nav__logo">
                        <Image
                            src={aacLogoHorizontal}
                            fill
                            style={{objectFit: 'contain'}}
                            priority={true}
                            sizes="300px"
                            alt="American Addiction Centers Image"
                        />
                    </div>
                </Link>
            </div>
            <div>
                <MobileNavTriggers />
            </div>
            <style jsx>
                {`
                    .mobile-nav__logo {
                        display: block;
                        max-width: 240px;
                        height: 100%;
                        position: relative;
                        margin-right: 4px;
                        margin: 0 8px;
                    }
                    .mobile-nav__header {
                        height: 56px;
                        background: #fff;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 4px;
                        align-items: center;
                        z-index: 100;
                        border-bottom: 1px solid var(--gray-200);
                    }
                    .mobile-nav__header > div {
                        position: relative;
                        height: 56px;
                        max-width: 100%;
                    }
                `}
            </style>
        </div>
    );
};
export default MobileNavHeader;
