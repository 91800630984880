import IconCheckCircle from 'aac-components/components/Icons/Basic/IconCheckCircle';
import Image from 'next/image';
import AcfTooltip from '../Tooltips/AcfTooltip';
import {aacLogoHorizontal} from '../../lib/globals';

const VobHeader = () => {
    return (
        <div className="vob-header">
            <div
                style={{
                    position: 'relative',
                    minHeight: '50px',
                    width: '250px',
                    margin: '0 auto 24px auto',
                }}>
                <Image
                    src={aacLogoHorizontal}
                    width={0}
                    height={0}
                    sizes="300px"
                    style={{height: '50px', width: 'auto'}}
                    alt="American Addiction Centers Logo"
                />
            </div>
            <div className="content">
                <div className="content__header">
                    Instantly Check Your Insurance Coverage
                </div>
                <ul className="content__list">
                    <li>
                        <span>
                            <IconCheckCircle
                                width="22"
                                height="22"
                                fill="var(--success)"
                            />
                        </span>
                        <span>Insurance can be confusing, let us handle it instead.</span>
                    </li>
                    <li>
                        <span>
                            <IconCheckCircle
                                width="22"
                                height="22"
                                fill="var(--success)"
                            />
                        </span>
                        <span>
                            American Addiction Centers has years of experience in the
                            addiction space and is in-network with many big-name insurance
                            providers.
                        </span>
                    </li>
                    <li>
                        <span>
                            <IconCheckCircle
                                width="22"
                                height="22"
                                fill="var(--success)"
                            />
                        </span>
                        <span>
                            By using our form below, we can determine if you are
                            in-network at one of our facilities.
                        </span>
                    </li>
                    <li>
                        <span>
                            <IconCheckCircle
                                width="22"
                                height="22"
                                fill="var(--success)"
                            />
                        </span>
                        <span>
                            Your insurance provider will not be notified or contacted by
                            completing this form.
                        </span>
                    </li>
                </ul>
                <div className="content__expect">
                    <AcfTooltip type="ssvob" />
                </div>
            </div>
            <style jsx>
                {`
                    .content__header {
                        font-weight: bold;
                        text-align: center;
                        margin-bottom: 24px;
                    }
                    .content__list {
                        list-style: none;
                        margin-bottom: 24px;
                    }
                    .content__list > li {
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-gap: 8px;
                        margin-bottom: 16px;
                    }
                    .content__list > li span:nth-child(1) {
                        margin-top: 4px;
                    }
                    .content__expect {
                        font-size: 14px;
                        display: grid;
                        grid-template-columns: auto auto;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 4px;
                    }
                `}
            </style>
        </div>
    );
};
export default VobHeader;
