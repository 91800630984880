import VobApp from 'aac-components/components/VobApp';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import AcfTooltip from '../../Tooltips/AcfTooltip';
import CallrailLink from '../../CallrailLink';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Image from 'next/image';
import url from 'url';
import {useRouter} from 'next/router';

const faqs = [
    {
        question: 'What is the purpose of this form?',
        answer: 'By filling out and submitting this form, you will instantly receive results confirming if your insurance provider is actively covering addiction related services and treatments or not. If your insurance is in network with American Addiction Centers (AAC) you will be notified by our admission navigators.',
    },
    {
        question: 'What are the next steps?',
        answer: 'After filling in our rehab insurance verification form you will be contacted by an American Addiction Centers (AAC) admissions navigator who will provide you with information to help you make an informed decision about addiction treatment. Our admissions navigators are available to answer any questions you may have as you consider whether treatment with AAC is right for you.',
    },
    {
        question: 'Which treatment centers can I go to?',
        answer: 'American Addiction Centers (AAC) has multiple locations across the country. Depending on your insurance coverage, our admission navigators will provide information about which location will best cover your treatment and fit your treatment needs. See our locations.',
    },
];

const Insurance = (props) => {
    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);
    return (
        <div className="insurance">
            <h2 className="heading">Ready to reclaim your life?</h2>
            <div className="insurance__copy">
                Take the first step towards healing and transformation by using our free
                online tool to see if your health insurance covers treatment
            </div>
            <div
                style={{
                    fontWeight: 'bold',
                    color: 'var(--primary)',
                    textAlign: 'center',
                    marginBottom: '40px',
                }}>
                Your insurance provider will not be notified or contacted by completing
                this form.
            </div>
            <div className="insurance__vob">
                <VobAbandonModal>
                    <VobApp type="multi-step" />
                </VobAbandonModal>
                <div className="what-to-expect">
                    <AcfTooltip type="ssvob" />
                </div>
            </div>
            <div className="insurance__call">
                If you would like to reach out to us immediately, please call{' '}
                <CallrailLink />
            </div>
            <section className="insurance__info">
                <div>
                    <h2>What you’ll need to get started:</h2>
                </div>
                <div className="info__card">
                    <div className="info__card--img">
                        <Image
                            src="/static/health-insurance-icon.png"
                            alt="American Addiction Centers Photo"
                            width={0}
                            height={0}
                            sizes="60px"
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '60px',
                                maxWidth: '60px',
                            }}
                        />
                    </div>
                    <div className="info__card--title">Insurance Information</div>
                    <div className="info__card--copy">
                        This information will help determine if your insurance covers
                        treatment.
                    </div>
                </div>
                <div className="info__card">
                    <div className="info__card--img">
                        <Image
                            src="/static/phone-icon.png"
                            alt="American Addiction Centers Photo"
                            width={0}
                            height={0}
                            sizes="200px"
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '200px',
                                maxWidth: 'fit-content',
                            }}
                        />
                    </div>
                    <div className="info__card--title">Contact Information</div>
                    <div className="info__card--copy">
                        We will contact you within an hour to discuss your options for
                        treatment.
                    </div>
                </div>
            </section>
            <div className="faqs">
                <div className="faqs__container">
                    {Array.isArray(faqs) &&
                        faqs.map((faq) => {
                            let linkToRender =
                                pathname === '/treatment-centers'
                                    ? '#our-facilities'
                                    : '/treatment-centers';

                            const answerWithLink = faq.answer.replace(
                                'See our locations',
                                `<a href="${linkToRender}">See our locations</a>`,
                            );
                            return (
                                <div className="faq__card" key={faq?.question}>
                                    <div
                                        className="faq__card--question"
                                        dangerouslySetInnerHTML={{__html: faq?.question}}
                                    />
                                    <div
                                        className="faq__card--answer"
                                        dangerouslySetInnerHTML={{__html: answerWithLink}}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <style jsx>
                {`
                    .heading {
                        margin-bottom: 24px;
                        text-align: center;
                    }
                    .insurance {
                        padding-top: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance {
                            padding-top: 80px;
                        }
                    }
                    .insurance__info {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                        padding: 40px 0;
                        max-width: 252px;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance__info {
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 80px 0;
                            align-items: center;
                            max-width: 770px;
                            padding: 80px 0;
                        }
                    }
                    .info__card {
                        text-align: center;
                    }
                    .info__card--img {
                        margin-bottom: 8px;
                    }
                    .info__card--title {
                        margin-bottom: 8px;
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .insurance__vob {
                        margin: 0 auto;
                        max-width: 400px;
                    }
                    .insurance__vob > div {
                        max-width: fit-content;
                        margin: 0 auto 24px auto;
                    }
                    .what-to-expect {
                        padding-top: 25px;
                    }
                    .insurance__copy {
                        max-width: 550px;
                        margin: 0 auto 24px auto;
                        text-align: center;
                        line-height: 24px;
                    }
                    .insurance__call {
                        text-align: center;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .faqs {
                        border-top: 1px solid var(--gray-200);
                    }
                    .faqs__container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                        padding: 40px 16px 0 16px;
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .faqs__container {
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 40px;
                            padding: 80px 0 0 0;
                        }
                    }
                    .faq__card {
                        background: rgba(248, 238, 229, 0.4);
                        border-radius: 16px;
                        padding: 40px;
                    }
                    .faq__card--question {
                        font-size: 24px;
                        font-weight: 700;
                        margin-bottom: 24px;
                    }
                    .faq__card--answer {
                        font-size: 14px;
                        line-height: 20px;
                    }
                `}
            </style>
        </div>
    );
};
export default Insurance;
