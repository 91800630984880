import {useState, useEffect} from 'react';
import {debounce} from 'aac-components/utils/throttle';

// hook to identify when a user has scrolled at least 100px down the page
const onUserScroll = (offset = 100) => {
    const [userScrolled, updateScroll] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            const windowHeight = window && window.pageYOffset;
            if (windowHeight >= offset) {
                updateScroll(true);
            } else {
                updateScroll(false);
            }
        };

        window.addEventListener('scroll', debounce(onScroll, 200));
        // return a function to remove event listeners
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return userScrolled;
};
export default onUserScroll;
