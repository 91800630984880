import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import {useContext} from 'react';
import {PagePillContext} from '..';
import VobHeader from './VobHeader';
import VobApp from 'aac-components/components/VobApp';
import HardcodedPillStyles from './HardcodedPillStyles';
import CallrailLink from '../../CallrailLink';
import Link from 'next/link';

const EvidenceBased = () => {
    const {setIsOpen} = useContext(PagePillContext);

    return (
        <div>
            <HardcodedPillStyles>
                <div>
                    <div className="title">Unlock Hope With Evidence-Based Care</div>
                    <div className="copy">
                        Our{' '}
                        <Link
                            href="/treatment-centers"
                            title="Our Addiction Treatment Centers"
                            style={{textDecoration: 'none'}}>
                            addiction treatment centers
                        </Link>{' '}
                        prioritize evidence-based therapies, such as cognitive and
                        dialectical behavioral therapy, motivational interviewing, and
                        medication-assisted treatment, tailored to individual needs for
                        effective, holistic recovery. These approaches are grounded in
                        science, increasing the likelihood of recovery for those
                        struggling with addiction.
                    </div>
                    <div className="copy">
                        Call our professional admissions navigators at{' '}
                        <CallrailLink style={{textDecoration: 'none'}} /> for a free,
                        private consultation, or{' '}
                        <Link
                            href="/not-ready-to-talk"
                            title="Sign Up for 24/7 Text Support"
                            style={{textDecoration: 'none'}}>
                            sign up for 24/7 text support
                        </Link>
                        .
                    </div>
                </div>
                <div className="vob__container">
                    <VobHeader />
                    <VobAbandonModal handleFinishClick={() => setIsOpen(true)}>
                        <VobApp type="multi-step" />
                    </VobAbandonModal>
                </div>
                <style jsx>
                    {`
                        .vob__container {
                            margin: 40px 0;
                        }
                    `}
                </style>
            </HardcodedPillStyles>
        </div>
    );
};
export default EvidenceBased;
