import React from 'react';
import {alphabetizeArrayByKey, isMappableArray} from 'aac-components/utils/helpers';
import StatesNotListedCta from '../FacilitiesByRegion/StatesNotListedCta';
import FacilityCard from './FacilityCard';
import {BREAKPOINT} from '../../lib/styles';
import FacilityCardComplexOutpatient from './FacilityCardComplexOutpatient';

const FacilitiesByRegionOutpatient = ({facilities = [], selectedState = null}) => {
    let facilitesByState =
        isMappableArray(facilities) &&
        facilities?.reduce((acc, curr, i) => {
            const hasState = !!acc?.find(
                (x) => x?.state == curr?.acf?.aac_facility_state,
            );
            if (!hasState) {
                acc.push({state: curr?.acf?.aac_facility_state, facilities: [curr]});
            } else {
                const existingObj = acc?.find(
                    (x) => x?.state === curr?.acf?.aac_facility_state,
                );
                existingObj?.facilities?.push(curr);
            }

            return acc;
        }, []);

    let sortedFacilities = alphabetizeArrayByKey(facilitesByState, 'state');

    return (
        <div className="">
            {isMappableArray(sortedFacilities) &&
                sortedFacilities?.map((state) => {
                    const facilities = state?.facilities || [];
                    if (
                        selectedState !== null &&
                        selectedState !== state?.state &&
                        selectedState !== 'View All'
                    )
                        return null;
                    return (
                        <div className="state__section" key={state?.state}>
                            <div
                                className="state__title"
                                dangerouslySetInnerHTML={{
                                    __html: `${state?.state} (${facilities?.length} ${
                                        facilities?.length > 1 ? 'Facilities' : 'Facility'
                                    })`,
                                }}
                            />
                            <div className="fac__container">
                                {isMappableArray(facilities) &&
                                    facilities?.map((fac) => {
                                        return (
                                            <React.Fragment key={fac?.id}>
                                                <FacilityCardComplexOutpatient
                                                    facility={fac}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            {selectedState === 'State Not Listed' && <StatesNotListedCta />}
            <style jsx>
                {`
                    .state__title {
                        color: var(--primary-200);
                        border-bottom: 2px solid var(--primary-100);
                        padding-bottom: 4px;
                        margin-bottom: 16px;
                        font-size: 16px;
                    }
                    .state__section {
                        margin-bottom: 40px;
                    }
                    .fac__container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .fac__container {
                            grid-template-columns: 1fr 1fr;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default FacilitiesByRegionOutpatient;
