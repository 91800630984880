import HardcodedPillStyles from './HardcodedPillStyles';
import Link from 'next/link';
import CallrailButton from '../../CallrailButton';
import Button from 'aac-components/components/Button';
import styled from 'styled-components';

const OutpatientOptions = () => {
    return (
        <div>
            <HardcodedPillStyles>
                <div>
                    <div className="title">
                        Outpatient treatment meets you where you are.
                    </div>
                    <div className="copy">
                        Outpatient treatment allows patients to live at home or in a sober
                        living environment while receiving care. Evening and weekend
                        treatment opportunities along with telehealth care may also be
                        available, enabling some patients to maintain responsibilities
                        related to work, family, school, and more. Meanwhile, aftercare
                        programs offer ongoing care following more formalized inpatient
                        and outpatient treatment.
                    </div>
                    <div className="copy">
                        American Addiction Centers offers multiple outpatient and
                        aftercare options at facilities nationwide.
                    </div>
                    <LinkStyles>
                        <ul>
                            <li>
                                <Link
                                    href="/rehab-guide/outpatient-treatment"
                                    title="Outpatient Therapy">
                                    Outpatient Therapy
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href="/intensive-outpatient-programs"
                                    title="Intensive Outpatient Programs">
                                    Intensive Outpatient Programs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href="/rehab-guide/partial-hospitalization-programs"
                                    title="Partial Hospitalization Programs">
                                    Partial Hospitalization Programs
                                </Link>
                            </li>
                            <li>
                                <Link href="/telehealth" title="Telehealth">
                                    Telehealth
                                </Link>
                            </li>
                            <li>
                                <Link href="/rehab-guide/aftercare" title="Aftercare">
                                    Aftercare
                                </Link>
                            </li>
                        </ul>
                    </LinkStyles>
                    <div className="copy">
                        Learn more about outpatient treatment and aftercare solutions near
                        you
                    </div>
                    <div className="copy">
                        <CallrailButton style={{width: '100%', marginBottom: '8px'}} />
                        <Button
                            href="/not-ready-to-talk"
                            title="Sign Up for 24/7 Text Support"
                            theme="invert"
                            style={{width: '100%'}}>
                            Sign Up for 24/7 Text Support
                        </Button>
                    </div>
                </div>
            </HardcodedPillStyles>
        </div>
    );
};
export default OutpatientOptions;

const LinkStyles = styled.div`
    a {
        color: var(--secondary-300);
        text-decoration: none;
    }
`;
