import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Image from 'next/image';
import {useContext} from 'react';
import AppContext from '../AppContext';
import Carousel from 'aac-components/components/Carousel';

const reasons = [
    {
        title: 'We Stand By Our Care',
        copy: 'If you successfully complete our 90-day program, you may return for a complimentary 30 days of our treatment in the event of a relapse.',
    },
    {
        title: 'Access to Care',
        copy: 'Our services are made accessible to those in need through acceptance of many insurance plans and nationwide rehab center locations. ',
    },
    {
        title: 'Continuous Support',
        copy: 'We don’t just help you get clean, we help you stay clean. We offer sober living and a large network of alumni support nationwide.',
    },
];

const TopReasons = () => {
    const {isMobile} = useContext(AppContext);

    return (
        <div className="section__top-reasons">
            <div className="container">
                <div className="header">
                    <div className="header__logo">
                        <Image
                            src="/static/aac_icon_white.svg"
                            height={120}
                            width={120}
                            alt="American Addiction Centers Logo"
                        />
                    </div>
                    <div>
                        <div className="header__title text-2xl bold">
                            Top Reasons to Choose American Addiction Centers
                        </div>
                        <div className="header__copy">
                            We know you have options when it comes to addiction treatment.
                            That’s why American Addiction Centers goes above and beyond to
                            help you achieve and maintain sobriety.
                        </div>
                    </div>
                </div>
                <div className="reasons">
                    {isMobile ? (
                        <Carousel
                            theme="light"
                            slides={reasons.map((reason) => {
                                return (
                                    <div className="reason__card" key={reason?.title}>
                                        <div
                                            className="reason__card--title"
                                            dangerouslySetInnerHTML={{
                                                __html: reason?.title,
                                            }}
                                        />
                                        <div
                                            className="reason__card--copy"
                                            dangerouslySetInnerHTML={{
                                                __html: reason?.copy,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        />
                    ) : (
                        <>
                            {reasons.map((reason) => {
                                return (
                                    <div className="reason__card" key={reason?.title}>
                                        <div
                                            className="reason__card--title"
                                            dangerouslySetInnerHTML={{
                                                __html: reason?.title,
                                            }}
                                        />
                                        <div
                                            className="reason__card--copy"
                                            dangerouslySetInnerHTML={{
                                                __html: reason?.copy,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>

            <style jsx>
                {`
                    .section__top-reasons {
                        background: linear-gradient(
                                90deg,
                                rgba(29, 39, 88, 0.6) 0%,
                                rgba(36, 89, 169, 0.6) 100%
                            ),
                            #151d47;
                        margin: 0 0 60px 0;
                        color: #fff;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .section__top-reasons {
                            margin: 0 0 120px 0;
                        }
                    }
                    .container {
                        padding: 40px;
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            padding: 80px;
                        }
                    }
                    .header {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                        max-width: 900px;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .header {
                            grid-template-columns: 120px auto;
                            grid-gap: 80px;
                            align-items: center;
                            margin: 0 auto 48px auto;
                        }
                    }

                    .header__logo {
                        position: relative;
                        width: 120px;
                        height: 120px;
                        margin: 0 auto;
                    }
                    .header__title {
                        margin-bottom: 16px;
                    }
                    .header__copy {
                        line-height: 24px;
                        margin-bottom: 30px;
                    }
                    .reasons {
                        display: block;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .reasons {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 28px;
                        }
                    }
                    .reason__card {
                        border-left: 4px solid var(--focus);
                        padding-left: 16px;
                    }
                    .reason__card--title {
                        margin-bottom: 16px;
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .reason__card--copy {
                        font-size: 14px;
                        line-height: 20px;
                    }
                `}
            </style>
        </div>
    );
};
export default TopReasons;
