import {useContext, useEffect, useMemo, useState} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import IconStarBoxed from 'aac-components/components/Icons/Star/IconStarboxed';
import googleRatings from '../../lib/google-ratings';
import {BREAKPOINT} from 'aac-components/utils/styles';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import {useRouter} from 'next/router';
import AppContext from '../AppContext';
import url from 'url';

// in the case where we're already on the fac page, just scroll to top
const FacilityCardWrapper = (props) => {
    const {title: {rendered: title = ''} = {}, link = '', slug = ''} = ({} = props);
    const {
        pageProps: {
            data: {slug: currentPageSlug},
        },
    } = ({} = useContext(AppContext));

    const handleScroll = () => {
        window?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const href = url?.parse(link)?.pathname || '/treatment-centers/outpatient-rehab';
    return (
        <>
            {currentPageSlug === slug ? (
                <div onClick={handleScroll} title={title}>
                    <FacilityCard {...props} />
                </div>
            ) : (
                <Link
                    href={href}
                    title={title}
                    style={{textDecoration: 'none', color: 'inherit'}}>
                    <FacilityCard {...props} />
                </Link>
            )}
        </>
    );
};
export default FacilityCardWrapper;

const FacilityCard = (props = {}) => {
    const {
        title: {rendered: title = ''} = {},
        slug = '',
        featured_image = [],
        acf: {aac_facility_address: address = ''} = {},
        insuranceName = '',
        inNework = false,
        geolocationMatch = false,
    } = ({} = props);
    const photo = featured_image?.[6] || null;

    const router = useRouter();

    useEffect(() => {
        window?.lazyLoadInstance?.update();
    }, [router?.asPath]);

    const googleRating = googleRatings.filter((item) => item.slug === slug)?.[0] || {};

    return (
        <div
            title={title}
            className={`facility-card ${
                geolocationMatch ? 'geo-match' : 'non-geo-match'
            }`}>
            <div className="facility-card__image">
                {photo ? (
                    <Image
                        alt={title}
                        src={photo}
                        fill
                        style={{objectFit: 'cover'}}
                        sizes={`(min-width: ${BREAKPOINT}) 100vw, 300px`}
                    />
                ) : (
                    <Image
                        alt={title}
                        src="/static/aac_icon_white.svg"
                        fill
                        style={{
                            objectFit: 'contain',
                            padding: '24px',
                        }}
                        sizes={`(min-width: ${BREAKPOINT}) 100vw, 300px`}
                    />
                )}
                {geolocationMatch && (
                    <div className="geo-match__title">Closest to you</div>
                )}
            </div>
            <div className="facility-card__content">
                <div
                    className="facility-card__title"
                    dangerouslySetInnerHTML={{__html: title}}
                />
                <div
                    className="facility-card__address"
                    dangerouslySetInnerHTML={{__html: address}}
                />
                <div className="facility-card__details">
                    {googleRating?.rating && (
                        <div className="facility-card__rating">
                            <div className="facility-card__rating--star">
                                <IconStarBoxed width="16" height="16" />
                            </div>
                            <div className="facility-card__rating--number">
                                {googleRating?.rating}
                            </div>
                            {googleRating?.count && (
                                <div className="facility-card__rating--count">
                                    ({googleRating?.count})
                                </div>
                            )}
                        </div>
                    )}
                    {inNework && (
                        <div className="facility-card__in-network">
                            In-Network {insuranceName && <>with {insuranceName}</>}
                        </div>
                    )}
                </div>
            </div>
            <style jsx>
                {`
                    :global(.facility-card p) {
                        font-size: 12px;
                        line-height: 16px;
                        margin: 0;
                    }
                    .facility-card {
                        width: 100%;
                        min-width: 300px;
                        border-radius: 8px;
                        background: #fff;
                        text-align: left;
                        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                        cursor: pointer;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facility-card {
                            width: 300px;
                        }
                    }
                    .facility-card.geo-match {
                        border: 3px solid var(--warning);
                    }
                    .geo-match__title {
                        position: absolute;
                        background: rgba(255, 173, 13, 0.8);
                        bottom: 0;
                        padding: 4px 8px;
                        color: var(--primary-400);
                        border-radius: 0 4px 0 0;
                        border-top: 1px solid var(--warning);
                        border-right: 1px solid var(--warning);
                        text-align: right;
                        font-size: 12px;
                        font-weight: 600;
                        left: 0;
                        cursor: pointer;
                    }
                    a {
                        text-decoration: none;
                        color: var(--gray-400);
                        position: relative;
                    }
                    .facility-card__image {
                        position: relative;
                        height: 200px;
                        width: 100%;
                        border-radius: 8px 8px 0 0;
                        overflow: hidden;
                        background: var(--primary);
                    }
                    .facility-card__content {
                        padding: 16px;
                        min-height: 140px;
                    }
                    .facility-card__title {
                        font-weight: bold;
                        margin-bottom: 8px;
                        min-height: 40px;
                        color: var(--gray-400);
                    }
                    .facility-card__address {
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 8px;
                        min-height: 32px;
                    }
                    .facility-card__rating {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        min-height: 20px;
                    }
                    .facility-card__rating--star {
                        margin-right: 4px;
                    }
                    .facility-card__rating--number {
                        font-weight: bold;
                    }
                    .facility-card__rating--count {
                        font-size: 12px;
                        margin-left: 4px;
                    }
                    .facility-card__details {
                        display: flex;
                        align-items: center;
                    }
                    .facility-card__in-network {
                        color: var(--tertiary);
                        font-weight: bold;
                        font-size: 12px;
                        margin-left: 8px;
                    }
                `}
            </style>
        </div>
    );
};
