import CallrailLink from '../../CallrailLink';
import facilityInfo from '../../../lib/facility-info.json';
import {isMappableArray} from 'aac-components/utils/helpers';
import Image from 'next/image';
import Link from 'next/link';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../../AppContext';
import HardcodedPillStyles from './HardcodedPillStyles';

const MedicalDetox = ({title = ''}) => {
    const context = useContext(AppContext);
    const [facilities, setFacilities] = useState([]);

    const geoLocationFac = context?.sessionStorageState?.geolocation?.facility;
    const slug = context?.pageProps?.data?.slug;

    useEffect(() => {
        const facArr = Object.keys(facilityInfo).reduce((acc, curr) => {
            if (!curr.includes('resolution') && !curr.includes('outpatient')) {
                acc.push({slug: curr, ...facilityInfo[curr]});
            }
            return acc;
        }, []);

        if (geoLocationFac) {
            const indexMatch = facArr.findIndex((x) => x?.siteKey === geoLocationFac);
            const firstFac = facArr?.[indexMatch];

            facArr.splice(indexMatch, 1);
            facArr.unshift(firstFac);
        }
        setFacilities(facArr);
    }, [geoLocationFac]);

    return (
        <HardcodedPillStyles>
            <div className="container">
                <div className="title">{title || 'Medical Detox'}</div>
                <div>
                    Minimize the discomfort and health risks of withdrawal and set the
                    foundation for addiction recovery. Find American Addiction Centers
                    facilities offering 24/7 medical detox services below.
                </div>{' '}
                <div>
                    You can also call our professional admissions navigators 24/7 at{' '}
                    <CallrailLink /> for a free, private consultation today.
                </div>
                <div className="facilities">
                    {isMappableArray(facilities) &&
                        facilities.map((fac) => {
                            if (slug === fac?.slug) return null;
                            return (
                                <div className="facility__card" key={fac?.slug}>
                                    <Link
                                        href={
                                            `/treatment-centers/${fac?.slug}` ||
                                            '/treatment-centers'
                                        }
                                        title={fac?.name}>
                                        <div className="facility__card--img">
                                            <Image
                                                src={fac?.img}
                                                sizes="200px"
                                                fill
                                                alt={fac?.name}
                                                style={{objectFit: 'cover'}}
                                            />
                                        </div>
                                        <div className="facility__card--location">
                                            <div style={{margin: '4px 4px 0 0'}}>
                                                <IconLocation
                                                    fill="#fff"
                                                    width="16"
                                                    height="16"
                                                />
                                            </div>
                                            {fac?.location?.city},{' '}
                                            {fac?.location?.state_code}
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                </div>
            </div>
            <style jsx>
                {`
                    .container > div {
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    .facilities {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 16px;
                    }
                    .facility__card {
                        min-height: 170px;
                        position: relative;
                    }
                    .facility__card--img {
                        position: relative;
                        width: 100%;
                        height: 170px;
                    }
                    .facility__card--location {
                        position: relative;
                        bottom: 0;
                        position: absolute;
                        color: #fff;
                        font-size: 12px;
                        padding: 4px 8px;
                        min-width: 100%;
                        background: rgba(000, 000, 000, 0.6);
                        display: flex;
                        align-items: center;
                    }
                `}
            </style>
        </HardcodedPillStyles>
    );
};
export default MedicalDetox;
