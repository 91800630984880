const googleRatings = [
    {
        slug: 'adcare-rhode-island-outpatient',
        rating: '',
    },
    {
        slug: 'adcare-hospital-outpatient',
        rating: '',
    },
    {
        slug: 'adcare-hospital',
        rating: '4.3',
        count: 335,
    },
    {
        slug: 'laguna-treatment-center',
        rating: '4.2',
        count: 424,
    },
    {
        slug: 'river-oaks',
        rating: '3.6',
        count: 363,
    },
    {
        slug: 'greenhouse',
        rating: '4.3',
        count: '1.4k',
    },
    {
        slug: 'desert-hope',
        rating: '4.0',
        count: 432,
    },
    {
        slug: 'oxford',
        rating: '4.4',
        count: 604,
    },
    {
        slug: 'adcare-rhode-island',
        rating: '4.2',
        count: 196,
    },
    {
        slug: 'recovery-first-hollywood',
        rating: '4.6',
        count: 819,
    },
    {
        slug: 'resolutions',
        rating: '',
    },
];
export default googleRatings;
