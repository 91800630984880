import useIsComponentMounted from './useIsComponentMounted';
import {useState} from 'react';

export default function useStateIfMounted(initialValue) {
    const isComponentMounted = useIsComponentMounted();
    const [state, setState] = useState(initialValue);
    function newSetState(value) {
        if (isComponentMounted.current) {
            setState(value);
        }
    }
    return [state, newSetState];
}
