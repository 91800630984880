import {useContext, useEffect, useState} from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import AktifyShortcode from '../Aktify/AktifyShortcode';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import VobApp from 'aac-components/components/VobApp';
import VobHeader from '../VobHeader';
import ContactForm from 'aac-components/components/ContactForm';
import AdmissionsContent from './AdmissionsContent';
import AppContext from '../AppContext';
import {getPreFillVtx} from '../PageTemplates/InsuranceCoverage';
import ContactFormAbandonModal from 'aac-components/components/ContactForm/ContactFormAbandonModal';
import VobHelpline from '../VobHelpline';
import SmsFormAbandonModal from 'aac-components/components/AktifyForm/SmsFormAbandonModal';
import {useMentalHealthVob} from '../../lib/utils';

const SlideInLeftCta = dynamic(() => import('aac-components/components/SlideInLeftCta'), {
    ssr: false,
});

const GlobalSliders = ({type = ''}) => {
    const {pageProps: {data: {slug = '', tags = []} = {}} = {}} = ({} =
        useContext(AppContext));
    const {callTrackingNumber} = useContext(AppContext);

    // for triggering slide in ssvob anywhere on the site:
    const [isSlideInOpen, setIsSlideInOpen] = useState({
        isOpen: false,
        type: '',
    });

    const trackSliderTrigger = (type, tracking = {}) => {
        if (window && window.heap) {
            window?.heap?.track('Open Global Slider', {
                ['type']: type,
                ...tracking,
            });
        }
    };

    useEffect(() => {
        if (window) {
            window.AAC = {};
            window.AAC.openSlideInSsvob = (e) => {
                setIsSlideInOpen({isOpen: true, type: 'vob'});
                trackSliderTrigger('vob');
            };
            window.AAC.openSlideInSms = () => {
                setIsSlideInOpen({isOpen: true, type: 'sms'});
                trackSliderTrigger('sms');
            };
            window.AAC.openSlideInContact = () => {
                setIsSlideInOpen({isOpen: true, type: 'contact'});
                trackSliderTrigger('contact');
            };
            window.AAC.openSlideInAdmissions = () => {
                setIsSlideInOpen({isOpen: true, type: 'admissions'});
                trackSliderTrigger('admissions');
            };
        }
    }, []);

    return (
        <SlideInLeftCta
            isOpen={isSlideInOpen?.isOpen || false}
            handleClose={() => {
                setIsSlideInOpen(false);
            }}>
            <div className={`slide-in ${isSlideInOpen.type === 'vob' ? 'show' : ''}`}>
                <div style={{marginBottom: '24px'}}>
                    <VobHeader />
                </div>
                <VobAbandonModal
                    callTrackingNumber={callTrackingNumber}
                    handleFinishClick={() =>
                        setIsSlideInOpen({isOpen: true, type: 'vob'})
                    }>
                    <VobApp
                        automationTestingSelector="slide-in-ssvob"
                        type="multi-step"
                        vtxOnLoad={getPreFillVtx(slug)}
                        mentalHealthForm={useMentalHealthVob(tags)}
                    />
                    <VobHelpline />
                </VobAbandonModal>
            </div>
            <div className={`slide-in ${isSlideInOpen.type === 'sms' ? 'show' : ''}`}>
                <AktifyStyles>
                    <SmsFormAbandonModal
                        callTrackingNumber={callTrackingNumber}
                        handleFinishClick={() =>
                            setIsSlideInOpen({isOpen: true, type: 'sms'})
                        }>
                        <AktifyShortcode automationTestingSelector="slide-in-aktify" />
                    </SmsFormAbandonModal>
                </AktifyStyles>
            </div>
            <div className={`slide-in ${isSlideInOpen.type === 'contact' ? 'show' : ''}`}>
                <ContactFormStyles>
                    <ContactFormAbandonModal
                        callTrackingNumber={callTrackingNumber}
                        handleFinishClick={() =>
                            setIsSlideInOpen({isOpen: true, type: 'contact'})
                        }>
                        <ContactForm
                            callTrackingNumber={callTrackingNumber}
                            singleColumnFields={true}
                            handleFinishClickForVobAbandon={() => {
                                setIsSlideInOpen({isOpen: true, type: 'contact'});
                            }}
                        />
                    </ContactFormAbandonModal>
                </ContactFormStyles>
            </div>
            {isSlideInOpen.type === 'admissions' && (
                <div
                    className={`slide-in ${
                        isSlideInOpen.type === 'admissions' ? 'show' : ''
                    }`}>
                    <AdmissionsContent />
                </div>
            )}
            <style jsx>
                {`
                    .slide-in {
                        display: none;
                    }
                    .slide-in.show {
                        display: block;
                    }
                `}
            </style>
        </SlideInLeftCta>
    );
};
export default GlobalSliders;

const AktifyStyles = styled.div`
    .container {
        border: none !important;
        padding: 30px 0 !important;
    }
`;
const ContactFormStyles = styled.div`
    .contact-vob__container {
        box-shadow: none;
    }
    .padded-container {
        padding: 0;
        border-radius: 8px;
    }
    .padded-container__top {
        padding: 0;
    }
    .tertiary-background {
        padding: 16px;
        border: 1px solid var(--tertiary-400, #e4eff1);
        border-radius: 8px;
        overflow: hidden;
    }
    .section__submit {
        margin-top: 40px;
    }
    .padded-container__top {
        padding: 0;
    }
`;
