import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import {useContext} from 'react';
import {PagePillContext} from '..';
import VobHeader from './VobHeader';
import VobApp from 'aac-components/components/VobApp';
import HardcodedPillStyles from './HardcodedPillStyles';
import CallrailLink from '../../CallrailLink';
import Link from 'next/link';

const EvidenceBased = () => {
    const {setIsOpen} = useContext(PagePillContext);

    return (
        <div>
            <HardcodedPillStyles>
                <div>
                    <div className="title">Where Compassion Meets Expertise.</div>
                    <div className="copy">
                        American Addiction Centers prioritize expert care, offering
                        personalized treatment plans for addiction. With experienced
                        clinicians, evidence-based therapies, and holistic support, we
                        empower individuals to achieve lasting recovery. Many of our
                        facilities are CARF and Joint Commission accredited to provide the
                        level of care you need.
                    </div>
                    <div className="copy">
                        Call our professional admissions navigators at{' '}
                        <CallrailLink style={{textDecoration: 'none'}} /> for a free,
                        private consultation, or{' '}
                        <Link
                            href="/not-ready-to-talk"
                            title="Sign Up for 24/7 Text Support"
                            style={{textDecoration: 'none'}}>
                            sign up for 24/7 text support
                        </Link>
                        .
                    </div>
                </div>
                <div className="vob__container">
                    <VobHeader />
                    <VobAbandonModal handleFinishClick={() => setIsOpen(true)}>
                        <VobApp type="multi-step" />
                    </VobAbandonModal>
                </div>
                <style jsx>
                    {`
                        .vob__container {
                            margin: 40px 0;
                        }
                    `}
                </style>
            </HardcodedPillStyles>
        </div>
    );
};
export default EvidenceBased;
