import Byline from 'aac-components/components/Byline';
import styled from 'styled-components';

const BylineStyles = styled.div`
    .c-byline {
        border: none;
        min-height: 40px;
        min-width: 100%;
    }
    .c-byline__entry {
        font-style: normal;

        color: ${(props) =>
            props?.theme?.theme === 'light' ? '#fff' : 'var(--gray-500)'};
    }
    .c-byline__entry:last-child {
        color: var(--gray-400);
        font-style: italic;
    }
`;
const BylineWrapper = (props) => {
    return (
        <BylineStyles theme={{theme: props?.theme}}>
            <Byline {...props} />
        </BylineStyles>
    );
};
export default BylineWrapper;
