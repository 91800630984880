import {useRouter} from 'next/router';
import {BREAKPOINT} from '../../lib/styles';
import useFetch, {useWpFetch} from '../../lib/hooks/useFetch';
import FacilityCard from './FacilityCard';
import {isMappableArray, splitPathname} from 'aac-components/utils/helpers';
import {useContext, useEffect, useRef, useState} from 'react';
import AppContext from '../AppContext';
import facilityInfo from '../../lib/facility-info.json';
import Filter from '../FacilitiesByRegion/Filter';
import StatesNotListedCta from '../FacilitiesByRegion/StatesNotListedCta';
import styled from 'styled-components';
import Button from 'aac-components/components/Button';
import {outpatientFacilitiesSlugs} from '../../pages/treatment-centers/outpatient-rehab';

/**
 * Ordering Priority:
 * 1. Geolocation - if geolocation match, always show that FAC first
 * 2. WP Order - facilties with higher "Order" number in WP go first
 * 3. In-Network - on /insurance-coverage pages, inNetworkIds is passed as prop,
 *    out of network fac should be pushed to the back.
 */

const FacilityCardSlider = ({
    showFilter = true,
    inNeworkIds = [],
    insuranceName = '',
    showViewAllButton = true,
    isOutpatient = false,
}) => {
    const router = useRouter();
    const clearCache = Boolean(router?.query?.clearCache);

    /** Treatment Center Data */
    const {
        pageProps: {data: {slug = ''} = {}} = {},
        sessionStorageState: {geolocation: {facility: geolocationSiteKey = ''} = {}} = {},
    } = ({} = useContext(AppContext));

    const facilitiesUrl = `/wordpress/wp/v2/facilities?orderby=menu_order&parent=0&per_page=50&_fields=slug,title,id,link,acf,featured_image`;
    const outpatientFacilitiesUrl = `/wordpress/wp/v2/facilities?per_page=50&slug=${outpatientFacilitiesSlugs?.join(
        ',',
    )}&_fields=id,title,yoast_head,acf,featured_image,slug,link`;

    let {data} = useWpFetch(
        isOutpatient ? outpatientFacilitiesUrl : facilitiesUrl,
        clearCache,
    );

    const [facilityCards, setFacilityCards] = useState([]);
    const [geolocationSlug, setGeolocationSlug] = useState('');
    const [filteredState, setFilteredState] = useState('View All');
    const [showingFacCount, setShowingFacCount] = useState(0);

    const sliderRef = useRef();

    let states = [];
    states =
        isMappableArray(facilityCards) &&
        facilityCards
            ?.reduce((acc, curr, i) => {
                const hasState = !!acc?.find((x) => x == curr?.acf?.aac_facility_state);
                if (!hasState) {
                    acc.push(curr?.acf?.aac_facility_state);
                }

                return acc;
            }, [])
            ?.sort();

    useEffect(() => {
        // if inNetworkIds provided, make sure those are at the front
        if (inNeworkIds?.length >= 1) {
            const facilitiesByNetwork =
                isMappableArray(data) &&
                data.reduce(
                    (acc, curr, i) => {
                        if (inNeworkIds.includes(curr?.id)) {
                            acc?.inNetwork?.push(curr);
                        } else {
                            acc?.outNetwork?.push(curr);
                        }
                        return acc;
                    },
                    {inNetwork: [], outNetwork: []},
                );
            const {inNetwork, outNetwork} = facilitiesByNetwork;
            setFacilityCards(inNetwork?.concat(outNetwork));
        } else if (geolocationSiteKey) {
            // if geolocation facility match, make that one the very first
            const geolocationSlugMatch = Object.keys(facilityInfo).reduce((acc, curr) => {
                if (facilityInfo?.[curr]?.siteKey == geolocationSiteKey) {
                    acc.push(curr);
                }
                return acc;
            }, [])?.[0];
            setGeolocationSlug(geolocationSlugMatch);
            const facilitiesSorted = data?.sort((x, y) => {
                return x?.slug === geolocationSlug ? -1 : 0;
            });
            setFacilityCards(facilitiesSorted);
        } else {
            setFacilityCards(data);
        }
    }, [inNeworkIds, data]);

    useEffect(() => {
        const cards = sliderRef?.current?.getElementsByClassName('facility-card');
        setShowingFacCount(cards?.length);
    }, [filteredState]);

    const hasFilteredState =
        filteredState !== 'View All' &&
        filteredState !== null &&
        filteredState !== 'States Not Listed';

    return (
        <>
            {showFilter && (
                <div className="filter">
                    <FilterStyles>
                        <Filter
                            label={'Filter By Location:'}
                            onChange={(state) => setFilteredState(state)}
                            showOutpatient={false}
                            selectedOption={filteredState}
                            options={
                                isMappableArray(states)
                                    ? [...states, 'States Not Listed', 'View All']
                                    : []
                            }
                        />
                    </FilterStyles>
                </div>
            )}
            {filteredState === 'States Not Listed' && <StatesNotListedCta />}
            <div className="facility-card-slider" ref={sliderRef}>
                {isMappableArray(facilityCards) &&
                    facilityCards.map((facility, index) => {
                        const inNework = !!inNeworkIds.find(
                            (item) => item === facility?.id,
                        );

                        // if on the current fac page, don't show it unless in the filter
                        if (facility?.slug === slug && !filteredState) return null;

                        if (
                            !!filteredState &&
                            filteredState !== 'View All' &&
                            filteredState !== 'States Not Listed' &&
                            facility?.acf?.aac_facility_state !== filteredState
                        )
                            return null;

                        return (
                            <div key={facility?.id}>
                                <FacilityCard
                                    {...facility}
                                    inNework={inNework}
                                    insuranceName={insuranceName}
                                    geolocationMatch={facility?.slug === geolocationSlug}
                                />
                            </div>
                        );
                    })}
            </div>
            {showViewAllButton && (
                <div className="button__container">
                    <Button
                        theme="invert"
                        title="View All Treatment Facilities"
                        href={`${
                            isOutpatient
                                ? '/treatment-centers/outpatient-rehab'
                                : '/treatment-centers'
                        }`}>
                        View All {isOutpatient ? 'Outpatient' : 'Treatment'} Facilities
                    </Button>
                </div>
            )}
            <style jsx>{`
                .facility-card-slider {
                    display: ${showingFacCount === 1 ? 'block' : 'flex'};
                    overflow-x: auto;
                    max-width: 100%;
                    min-height: 300px;
                    justify-content: flex-start;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .facility-card-slider {
                        display: flex;
                        justify-content: flex-start;
                    }
                }
                .facility-card-slider > div {
                    margin: 0 8px 16px 8px;
                }
                .filter {
                    max-width: 100%;
                    margin: 0 auto 24px auto;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .filter {
                        max-width: 300px;
                        margin: 0 0 24px auto;
                    }
                }
                .filter__label {
                    font-weight: bold;
                    text-align: left;
                    display: flex;
                    border: 1px solid red;
                    align-items: center;
                    justify-content: space-between;
                }
                .filter__label--clear {
                    font-size: 12px;
                    text-align: right;
                    font-weight: normal;
                    color: var(--tertiary);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .button__container {
                    margin: 60px auto 0 auto;
                    max-width: fit-content;
                }
                /* width */
                ::-webkit-scrollbar {
                    height: 8px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background: var(--gray-100);
                    border-radius: 10px;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: var(--gray-300);
                    border-radius: 10px;
                }
            `}</style>
        </>
    );
};

export default FacilityCardSlider;

const FilterStyles = styled.div`
    .filter__input {
        margin-top: 0;
    }
    .filter__options {
        max-height: 200px;
        overflow-y: scroll;
    }
    .filter__options::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    .filter__options::-webkit-scrollbar-track {
        background: var(--gray-100);
        border-radius: 4px;
    }

    /* Handle */
    .filter__options::-webkit-scrollbar-thumb {
        background: var(--gray-300);
        border-radius: 10px;
    }
`;
