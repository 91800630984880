import Image from 'next/image';
import {useState, useRef} from 'react';
import Button from 'aac-components/components/Button';
import Tooltip from 'aac-components/components/Tooltip';
import IconInfo from 'aac-components/components/Icons/Attention/IconInfo';
import Carousel from 'aac-components/components/Carousel';
import facilityInfo from '../../lib/facility-info.json';
import styled from 'styled-components';
import MaxWidth from '../../components/MaxWidth';
import {isMappableArray} from 'aac-components/utils/helpers';
import {splitPathname} from 'aac-components/utils/helpers';
import url from 'url';
import {BREAKPOINT} from 'aac-components/utils/styles';

const FacilityCardComplexOutpatient = ({facility = {}}) => {
    const {
        acf: {['aac_facility-gallery']: gallery = []} = {},
        featured_image = [],
        title: {rendered: title = ''} = {},
        link = '',
        excerpt: {rendered: excerpt = ''} = {},
    } = facility;

    const pathname = url.parse(link)?.pathname;
    const parentSlug = splitPathname(pathname)?.[1];
    const parentFacility = facilityInfo?.[parentSlug];
    const favicon = parentFacility?.favicon;

    const galleryTruncated = gallery?.slice(0, 5); // only show first few pics in gallery

    return (
        <div className="card">
            <div className="carousel-container">
                {isMappableArray(galleryTruncated) && galleryTruncated?.length > 1 ? (
                    <CarouselStyles>
                        <Carousel
                            theme="light"
                            initialSlide={0}
                            slides={galleryTruncated.map((imageUrl, index) => {
                                return (
                                    <div className="card__image">
                                        <Image
                                            src={imageUrl}
                                            alt={`American Addiction Centers Photo`}
                                            sizes="200px"
                                            fill
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        />
                    </CarouselStyles>
                ) : (
                    <div className="card__featured-img">
                        <Image
                            src={featured_image?.[3]}
                            alt="American Addiction Centers Photo"
                            sizes="200px"
                            fill
                            style={{
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="card__title">
                {favicon && (
                    <div className="card__logo">
                        <Image
                            src={favicon}
                            sizes="30px"
                            fill
                            alt="American Addiction Centers Photo"
                            style={{
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                        />
                    </div>
                )}
                <div dangerouslySetInnerHTML={{__html: title}} />
            </div>

            <div className="card__tooltip-container">
                <Tooltip
                    tagName="div"
                    tooltipContent={
                        <>
                            <div>
                                American Addiction Centers accepts many insurance
                                policies.{' '}
                                <a
                                    href={'/verify-insurance'}
                                    className="sub-cat__tooltip-link"
                                    title={`Verify Your Benefits`}>
                                    Verify your benefits
                                </a>{' '}
                                with our simple, secure form to see if you are covered for
                                treatment.
                            </div>
                        </>
                    }
                    renderChildren={() => (
                        <div
                            className="sub-cat__tooltip-icon"
                            title={`Insurance Accepted`}
                            style={{
                                display: 'flex',
                                marginBottom: '10px',
                                marginTop: '10px',
                            }}>
                            <IconInfo
                                fill="var(--interactive-300)"
                                width="24"
                                height="24"
                            />
                            <div style={{marginLeft: '5px', marginTop: '2px'}}>
                                Insurance Accepted
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="card__button">
                <Button
                    href={pathname}
                    style={{width: '100%'}}
                    title="View Treatment Center">
                    View Treatment Center
                </Button>
            </div>
            <style jsx>
                {`
                    .card {
                        width: 100%;
                        border: 2px solid rgb(255, 255, 255);
                        border-radius: 8px;
                        padding: 15px;
                        color: var(--gray-500) !important;
                        background-color: #ffffff;
                        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 1px,
                            rgba(0, 0, 0, 0.3) 0px 1px 2px;
                    }
                    .carousel-container {
                        max-width: 250px;
                        margin: 0;
                        min-width: 100%;
                    }
                    .card__image {
                        position: relative;
                        width: 100%;
                        border-radius: 8px;
                        height: 200px;
                        margin-bottom: 15px;
                    }
                    .card__logo {
                        position: relative;
                        width: 30px;
                        height: 30px;
                    }

                    .card__tooltip-container {
                        margin-bottom: 15px;
                    }
                    .card__insurance-accepted-container {
                        display: flex;
                        font-size: 12px;
                    }
                    .card__tooltip-title {
                        margin-left: 5px;
                        margin-top: 4px;
                    }
                    .card__state-reviews-container {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 8px;
                        margin-top: 8px;
                    }
                    .card__state {
                        font-weight: bold;
                    }
                    .card__title {
                        margin-bottom: 8px;
                        font-weight: bold;
                        display: grid;
                        grid-template-columns: 30px auto;
                        grid-gap: 8px;
                        align-items: center;
                    }
                    .card__featured-img {
                        position: relative;
                        width: 100%;
                        height: 200px;
                    }
                `}
            </style>
        </div>
    );
};

export default FacilityCardComplexOutpatient;

const CarouselStyles = styled.div`
    .slick-dots {
        width: 24px;
        height: 24px;
    }
    .slick-arrows {
        position: absolute;
        bottom: 20px;
        left: 46%;
    }
    .right-arrow {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .right-arrow svg {
        width: 32px;
        height: 32px;
    }
    .left-arrow {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    .left-arrow svg {
        width: 32px;
        height: 32px;
    }
    .slick-dots .dot {
        width: 8px;
        height: 8px;
        margin: 6px;
    }
    ul.dots {
        padding: 0;
    }
`;
