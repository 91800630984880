import {launchChat} from '../Drift/helpers';

const clickOptions = {
    chat: {
        text: 'Online Chat',
        onClick: launchChat,
    },
    aktify: {
        text: 'Sign Up for Text',
        onClick: () => window && window.AAC && window.AAC.openSlideInSms(),
    },
    aktify_2: {
        text: 'Text Support',
        href: '/not-ready-to-talk',
    },
    vob: {
        text: 'Check Your Coverage',
        href: '/verify-insurance',
    },
    facilities: {
        text: 'See Our Facilities',
        href: '/treatment-centers',
    },
    facilities_2: {
        text: 'See Our Treatment Facilities',
        href: '/treatment-centers',
    },
    admissions: {
        text: 'Admissions',
        href: '/admissions',
    },
    phone: {},
    outpatient_faqs: {
        text: 'See Outpatient FAQs',
        href: '/rehab-guide/outpatient-treatment/faq',
    },
    vob_veterans: {
        text: 'Check Veteran Benefits',
        href: '/verify-insurance/veterans',
    },

    form_scrollback: {
        text: 'Jump to Our Form',
        onClick: () => {
            if (window && typeof window !== 'undefined' && document) {
                const form = document.querySelectorAll(
                    'body > div:not(#modal-root) #vob-form',
                )?.[0];
                form &&
                    form.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
            }
        },
    },

    contact_scrollback: {
        text: 'Jump to Form',
        onClick: () => {
            if (window && typeof window !== 'undefined' && document) {
                const form = document.querySelector('.page-content #contact-form');
                form &&
                    form.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    });
            }
        },
    },
};
export default clickOptions;
