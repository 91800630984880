import React, {useState} from 'react';
import Link from 'next/link';
import {isSpanishPage} from '../../lib/utils';
import {withRouter} from 'next/router';
import url from 'url';

const RelatedTopics = (props) => {
    const {pages = [], router, title = ''} = props;
    const slug = router?.query?.slug || '';

    if (!pages || pages.length <= 0) return null;
    return (
        <div className="related-topics">
            <div className="text-2xl bold related-topics__title">
                {title
                    ? title
                    : isSpanishPage(slug)
                    ? 'Temas Relacionados'
                    : 'Related Topics'}
            </div>{' '}
            <nav role="navigation" className="related-topics">
                <ul>
                    {Array.isArray(pages) &&
                        pages.map((item) => {
                            if (!item) return null;

                            const {title = '', link = ''} = item;

                            const href = url.parse(link)?.pathname;

                            return (
                                <li key={href}>
                                    <Link href={href} legacyBehavior>
                                        <a
                                            title={title}
                                            dangerouslySetInnerHTML={{
                                                __html: title,
                                            }}
                                        />
                                    </Link>
                                </li>
                            );
                        })}
                </ul>
            </nav>
            <style jsx>
                {`
                    .related-topics {
                        background: #fff;
                        margin-bottom: 24px;
                    }
                    .related-topics__title {
                        margin-bottom: 24px;
                    }
                    a {
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 24px;
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }
                    li {
                        display: flex;
                        align-items: center;
                    }
                    li::before {
                        content: '•';
                        color: var(--gray-300);
                        font-weight: bold;
                        display: inline-block;
                        margin-right: 16px;
                        font-size: 24px;
                        line-height: 24px;
                    }
                `}
            </style>
        </div>
    );
};

export default withRouter(RelatedTopics);
