import CallrailLink from '../CallrailLink';

const VobHelpline = () => {
    return (
        <div className="container">
            Have questions about treatment or insurance? Speak with an admissions
            navigator to get help today at <CallrailLink />.
            <style jsx>
                {`
                    .container {
                        max-width: 490px;
                        margin: 24px auto;
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                    }
                `}
            </style>
        </div>
    );
};
export default VobHelpline;
