import Carousel from 'aac-components/components/Carousel';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {isMappableArray} from 'aac-components/utils/helpers';
import AppContext from '../AppContext';

const CarouselShortcode = (props) => {
    const [slides, setSlides] = useState([]);

    const interval = props?.interval || 10000;
    const {isMobile} = useContext(AppContext);

    useEffect(() => {
        const group = props?.group;
        const slides = Array.from(
            document?.getElementsByClassName(`carousel_card-${group}`),
        );
        setSlides(slides);
    }, []);

    // min 2 slides
    if (!isMappableArray(slides) || slides?.length < 2) return null;

    const cardsPerSlide = isMobile ? 1 : Number(props?.cardsperslide) || 1;

    return (
        <CarouselStyles>
            <Carousel
                cardsPerSlide={cardsPerSlide}
                interval={props?.autoslide === 'false' ? null : interval}
                slides={slides.map((slide) => {
                    return (
                        <>
                            <div
                                className="carousel__card"
                                dangerouslySetInnerHTML={{
                                    __html: slide?.innerHTML,
                                }}
                            />
                        </>
                    );
                })}
            />
            <style jsx>
                {`
                    .carousel__card {
                        border-radius: 8px;
                        min-height: 100%;
                        padding: 16px 15px;
                        max-width: 345px;
                        box-shadow: ${props?.['box-shadow'] ||
                        '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)'};
                    }
                    .carousel__card img {
                        border: 1px solid red;
                    }
                    .carousel__card p {
                        margin: 0;
                    }
                `}
            </style>
        </CarouselStyles>
    );
};
export default CarouselShortcode;

const CarouselStyles = styled.div`
    .slide-container {
        padding: 4px;
    }
    .slick-arrows {
        margin-top: 0;
    }
    .slick-dots .dots {
        padding: 0;
    }
    .slick-dots .dots li {
        display: flex;
    }
    .right-arrow,
    .left-arrow {
        display: flex;
    }
    .slide-container.grouped {
        grid-template-columns: repeat(auto-fit, minmax(100px, 345px));
        justify-content: center;
    }
`;
