import Image from 'next/image';
import {aacLogoHorizontal} from '../../../lib/globals';

const VobHeader = () => {
    return (
        <>
            <div className="logo">
                <Image
                    src={aacLogoHorizontal}
                    width={0}
                    height={0}
                    alt="American Addiction Centers Logo"
                    style={{width: 'auto', height: '48px'}}
                />
            </div>
            <div className="title">Check Your Insurance</div>
            <div className="copy">
                Instantly check the coverage offered by your insurance provider. 
            </div>
            <div className="copy">
                <ul>
                    <li>
                        Your information is confidential and protected under HIPAA privacy
                        rules. 
                    </li>
                    <li>
                        Receive a call, text, or email within an hour to discuss your
                        treatment options. 
                    </li>
                    <li>Zero obligation to enter treatment.</li>
                </ul>
            </div>
            <style jsx>
                {`
                    .logo {
                        text-align: center;
                        margin: 24px auto;
                    }
                    .title {
                        text-align: center;
                        font-weight: 700;
                        font-size: 24px;
                        margin-bottom: 16px;
                    }
                    .copy {
                        text-align: center;
                        line-height: 24px;
                        margin-bottom: 16px;
                    }
                    .copy ul {
                        text-align: left;
                        list-style: none;
                        margin-bottom: 24px;
                    }
                    .copy li {
                        margin-bottom: 16px;
                        font-size: 14px;
                        display: grid;
                        grid-template-columns: 10px auto;
                        grid-gap: 16px;
                        align-items: center;
                    }
                    .copy li:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: var(--secondary-300);
                        border-radius: 100%;
                        display: block;
                    }
                `}
            </style>
        </>
    );
};
export default VobHeader;
