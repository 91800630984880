import {useRouter} from 'next/router';
import url from 'url';
import styled from 'styled-components';
import CallrailLink from '../../CallrailLink';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';

const NextStepsNavDesktop = ({items = []}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    return (
        <div className="next-steps-nav__desktop">
            <div className="container">
                {Array.isArray(items) &&
                    items.map((item, index) => {
                        const {onClick, href, Icon, copy} = item;
                        if (href === pathname) return null;
                        return (
                            <div key={index}>
                                {onClick && (
                                    <div onClick={onClick} className="next-steps__item">
                                        <div className="next-steps__item--icon">
                                            <Icon
                                                width="22"
                                                height="22"
                                                fill="var(--primary-200)"
                                            />
                                        </div>
                                        <div
                                            className="next-steps__item--title"
                                            dangerouslySetInnerHTML={{
                                                __html: copy,
                                            }}
                                        />
                                        <div>
                                            <IconChevron
                                                rotate={'-90'}
                                                fill="var(--primary-200)"
                                            />
                                        </div>
                                    </div>
                                )}
                                {href && (
                                    <a
                                        href={href}
                                        title={copy}
                                        style={{color: '#fff', textDecoration: 'none'}}>
                                        <div className="next-steps__item">
                                            <div className="next-steps__item--icon">
                                                <Icon
                                                    width="22"
                                                    height="22"
                                                    fill="var(--primary-200)"
                                                />
                                            </div>
                                            <div
                                                className="next-steps__item--title"
                                                dangerouslySetInnerHTML={{__html: copy}}
                                            />
                                            <div>
                                                <IconChevron
                                                    rotate={'-90'}
                                                    fill="var(--primary-200)"
                                                />
                                            </div>
                                        </div>
                                    </a>
                                )}
                            </div>
                        );
                    })}
                <div className="next-steps__item phone">
                    <div>
                        <IconPhone fill="var(--primary-200)" />{' '}
                    </div>
                    <div>
                        <CallrailLink style={{color: '#fff', textDecoration: 'none'}} />
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .next-steps-nav__desktop {
                        height: 75px;
                        background: var(--primary-300);
                        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                    }
                    .container {
                        height: 75px;
                        max-width: ${MAX_WIDTH_PX};
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;
                    }

                    .next-steps__item {
                        color: #fff;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .next-steps__item > div:nth-child(1) {
                        margin-right: 8px;
                        display: flex;
                    }
                    .next-steps__item > div:last-child {
                        display: flex;
                        align-items: center;
                        margin-left: 8px;
                    }
                    .next-steps__item.phone > div:nth-child(1) {
                        position: relative;
                    }
                    .next-steps__item.phone > div:nth-child(1)::after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: var(--success);
                        border-radius: 100%;
                        right: 0px;
                        position: absolute;
                    }
                `}
            </style>
        </div>
    );
};
export default NextStepsNavDesktop;

const NextStepsNavItemsStyles = styled.div`
    span {
        color: #fff !important;
    }
    ul {
        min-width: 100% !important;
        display: grid !important;
        grid-template-columns: repeat(4, auto) !important;
        justify-content: space-between !important;
        grid-gap: 80px !important;
    }
    a {
        color: #fff !important;
        text-decoration: none;
    }
    svg {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(103%)
            contrast(103%);
        opacity: 0.7;
    }
    li:last-child .tooltip__icon svg:last-child {
        filter: none !important;
        opacity: 1 !important;
    }
`;
