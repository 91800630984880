import Link from 'next/link';
import styled from 'styled-components';
import MaxWidth from '../MaxWidth';
import {BREAKPOINT} from '../../lib/styles';
import RenderOnMobileOnly from '../RenderOnMobileOnly';
import RenderOnDesktopOnly from '../RenderOnDesktopOnly';

const TermsFooter = (props) => {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <div className="terms-footer">
            <MaxWidth>
                <LinkStyles>
                    <div className="terms-footer__inner">
                        <div>
                            <a href="/sitemap_index.xml">Sitemap</a>
                        </div>
                        <div>
                            <Link
                                href="/notice-of-privacy-practices"
                                title="Notice of Privacy Practices">
                                <RenderOnDesktopOnly>Notice of</RenderOnDesktopOnly>{' '}
                                Privacy Practices
                            </Link>
                        </div>
                        <div>
                            <Link href="/privacy-policy" title="Online Privacy Policy">
                                Online Privacy Policy
                            </Link>
                        </div>
                        <div>
                            <Link href="/terms-of-use" title="Terms of Use">
                                Terms of Use
                            </Link>
                        </div>
                        <div className="terms-footer__title">
                            <span style={{fontWeight: 'bold'}}>
                                © {year} American Addiction Centers
                            </span>
                        </div>
                    </div>
                </LinkStyles>
            </MaxWidth>
            <style jsx>
                {`
                    .terms-footer {
                        background: var(--gray-200);
                        padding: 20px 0 80px 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .terms-footer {
                            padding: 12px;
                        }
                    }
                    .terms-footer__title {
                        font-size: 12px;
                        font-weight: bold;
                        color: var(--primary);
                    }
                    .terms-footer__inner {
                        display: grid;
                        color: var(--primary);
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .terms-footer__inner {
                            grid-template-columns: repeat(5, auto);
                            align-items: center;
                            justify-content: center;
                            grid-gap: 24px;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default TermsFooter;

const LinkStyles = styled.div`
    a {
        font-size: 10px;
        text-decoration: none;
        color: var(--primary-300);
        display: block;
    }
`;
