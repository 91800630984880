import CallrailButton from '../CallrailButton';
import styled from 'styled-components';
import Image from 'next/image';
import {BREAKPOINT} from 'aac-components/utils/styles';
import {useContext} from 'react';
import AppContext from '../AppContext';

const VeteransCta = ({objectPosition = '-100px'}) => {
    const {isMobile} = useContext(AppContext);

    return (
        <div className="vet-cta">
            <div className="vet-cta__background">
                <Image
                    alt="American Addiction Centers Photo"
                    src="/static/veteran-background.png"
                    fill
                    sizes={`(min-width: ${BREAKPOINT}) 100vw, 300px`}
                    style={{
                        objectPosition,
                        objectFit: `${isMobile ? 'cover' : 'contain'}`,
                    }}
                />
            </div>
            <div className="vet-cta__content">
                <div className="vet-cta__background-blur" />
                <div className="vet-cta__content--inner">
                    <div className="vet-cta__title">
                        You served our country; let us help serve you.
                    </div>
                    <div className="vet-cta__copy">
                        Many of our facilities offer treatment programs specifically for
                        Veterans and accept <strong>TRICARE</strong> or work directly with
                        the <strong>VA</strong> as a referral partner. If you are a
                        Veteran or loved one of a vet struggling with substance misuse,
                        let us help you navigate your VA benefits, choose the best
                        treatment program for your needs, and determine your treatment
                        coverage.
                    </div>
                    <div className="vet-cta__button">
                        <CallrailButtonStyles>
                            <CallrailButton style={{minWidth: '100%'}} />
                        </CallrailButtonStyles>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .vet-cta {
                        background: #fff;
                        padding: 16px;
                        text-align: center;
                        border-radius: 8px;
                        position: relative;
                        overflow: hidden;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .vet-cta {
                            padding: 40px 16px;
                        }
                    }
                    .vet-cta__background {
                        opacity: 0.8;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .vet-cta__background {
                            opacity: 0.5;
                        }
                    }
                    .vet-cta__content {
                        position: relative;
                        border-radius: 8px;
                        box-shadow: var(--box-shadow);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .vet-cta__content {
                            background: none;
                            box-shadow: none;
                            padding: 0;
                        }
                    }
                    .vet-cta__content--inner {
                        position: relative;
                        padding: 16px;
                    }
                    .vet-cta__background-blur {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        backdrop-filter: blur(4px);
                        background: rgba(255, 255, 255, 0.8);
                        box-shadow: var(--box-shadow);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .vet-cta__background-blur {
                            display: none;
                        }
                    }
                    .vet-cta__title {
                        margin-bottom: 24px;
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .vet-cta__copy {
                        max-width: 575px;
                        margin: 0 auto;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    .vet-cta__button {
                        display: block;
                        min-width: 100%;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .vet-cta__button {
                            max-width: fit-content;
                            min-width: auto;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default VeteransCta;

const CallrailButtonStyles = styled.div`
    button {
        background-color: var(--tertiary);
        min-width: 100%;
    }
    button:hover {
        background-color: var(--secondary-400);
    }
    a {
        text-decoration: none;
    }
`;
