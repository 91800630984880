import {useContext, useEffect, useState} from 'react';

import AppContext from '../AppContext';
import {useRouter} from 'next/router';
import {isMappableArray, zIndex} from 'aac-components/utils/helpers';
import {BREAKPOINT} from 'aac-components/utils/styles';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import React from 'react';
import Link from 'next/link';
import DriftContext from '../Drift/DriftContext';
import url from 'url';
import StickyFooterContext from './StickyFooterContext';
import onUserScroll from '../../lib/hooks/onUserScroll';
import {getSegmentNumber} from '../../lib/utils';
import Button from 'aac-components/components/Button';
import AcfTooltip from '../Tooltips/AcfTooltip';
import clickOptions from './clickOptions';

const StickyFooter = () => {
    const {driftLoaded} = useContext(DriftContext);

    const {asPath} = useRouter();
    const {pathname} = url.parse(asPath);

    const {
        pageProps: {data: {tags = [], acf: {content_segment = ''} = {}} = {}} = {},
        isMobile,
    } = ({} = useContext(AppContext));

    const segmentNumber = getSegmentNumber(content_segment);

    const {stickyFooterState, updateStickyFooterState} = useContext(StickyFooterContext);

    const [state, setState] = useState({
        title: '',
        subtitle: '',
        buttons: [],
    });

    // handle closing sticky footer when user is entering vob form

    useEffect(() => {
        if (isMobile) {
            let vobForms =
                document &&
                document.querySelectorAll('body > div:not(#modal-root) #vob-form');
            vobForms = Array.from(vobForms);

            const handleFormFocus = () => {
                updateStickyFooterState({
                    isStickyFooterInView: true,
                    isStickyFooterExpanded: false,
                });
            };

            // add event listeners for focus on forms
            isMappableArray(vobForms) &&
                vobForms.forEach((form) => {
                    form?.addEventListener('focusin', handleFormFocus, false);
                });

            // cleanup
            return () => {
                isMappableArray(vobForms) &&
                    vobForms.forEach((form) => {
                        form?.removeEventListener('focusin', handleFormFocus, false);
                    });
            };
        }
    }, []);

    // phone tooltip
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const {
        acfOptions: {acf: {phone_tooltip: {label = '', copy = ''} = {}} = {}},
    } = useContext(AppContext);

    // scroll
    const scrolled = onUserScroll();
    useEffect(() => {
        updateStickyFooterState({
            isStickyFooterInView: scrolled,
            isStickyFooterExpanded: segmentNumber >= 4, //auto expand for higher segments
        });
    }, [scrolled]);

    // update values when segmentNumber changes or once drift is loaded
    useEffect(() => {
        let newState = {};

        const isVeteranPage = !!tags?.find((x) => x?.name == 'veterans');
        const isOutpatientPage = !!tags?.find((x) => x?.name == 'outpatient');

        if (segmentNumber <= 1) {
            newState = {
                title: 'Your Transformation Awaits',
                subtitle: 'Unlock a future filled with possibility.',
                buttons: ['vob', 'facilities'],
            };
        } else if (segmentNumber === 2) {
            newState = {
                title: 'Your Next Steps',
                subtitle: 'Have questions about recovery?',
                buttons: ['aktify', 'facilities_2'],
            };
        } else if (segmentNumber === 3) {
            newState = {
                title: 'Your Transformation Awaits',
                subtitle: 'Unlock a future filled with possibility.',
                buttons: ['vob', 'facilities'],
            };
        } else if (segmentNumber == 4) {
            newState = {
                title: 'Reclaim Your Life',
                subtitle: 'Begin your journey of healing today.',
                buttons: ['phone', 'vob'],
            };
        } else {
            // handles segmentNumber >= 5 & backups
            newState = {
                title: 'Not Quite Ready?',
                subtitle: "We're available to help you 24/7.",
                buttons: ['phone', 'vob'],
            };
        }

        // outpatient pages
        if (isOutpatientPage) {
            newState = {
                title: 'Questions about outpatient treatment?',
                subtitle:
                    'Our admissions navigators are ready to assist. Call us or verify your insurance coverage online.',
                buttons: ['phone', 'outpatient_faqs'],
            };
        }

        // veteran pages
        if (isVeteranPage) {
            newState = {
                title: 'Your Next Steps as a Veteran',
                subtitle: 'You served our country; let us help serve you.',
                buttons: ['vob_veterans', 'phone'],
            };
        }

        // VI page
        if (pathname?.includes('/verify-insurance')) {
            newState = {
                title: 'Take the First Step to Recovery',
                subtitle: 'Get Started With Treatment Today',
                buttons: ['form_scrollback', 'phone'],
            };
        }

        // contact us page
        if (pathname?.includes('/contact-us')) {
            newState = {
                title: 'Take the First Step to Recovery',
                subtitle: 'Get Started With Treatment Today',
                buttons: ['contact_scrollback', 'phone', 'vob'],
            };
        }
        setState(newState);
    }, [asPath]);

    const clickOptionOne = clickOptions?.[state?.buttons?.[0]];
    const clickOptionTwo = clickOptions?.[state?.buttons?.[1]];
    const clickOptionThree = clickOptions?.[state?.buttons?.[2]];

    const hasPhoneOption =
        state?.buttons?.filter((button) => {
            return button === 'phone';
        })?.length >= 1;

    return (
        <div
            id="sticky-footer-cta"
            className={`sf ${stickyFooterState?.isStickyFooterInView ? 'show' : ''}`}>
            <div
                className="sf__header"
                onClick={() =>
                    updateStickyFooterState({
                        isStickyFooterExpanded:
                            !stickyFooterState?.isStickyFooterExpanded,
                    })
                }>
                <div
                    className="sf__title"
                    dangerouslySetInnerHTML={{__html: state?.title}}
                />
                <div className="sf__icon">
                    <IconChevron
                        fill="var(--interactive)"
                        width="30"
                        height="30"
                        rotate={stickyFooterState?.isStickyFooterExpanded ? '0' : '180'}
                    />
                </div>
            </div>
            <div
                className={`sf__body ${
                    stickyFooterState?.isStickyFooterExpanded ? 'show' : ''
                }`}>
                <div
                    className="sf__subtitle"
                    dangerouslySetInnerHTML={{__html: state?.subtitle}}
                />
                <div className="sf__buttons">
                    {clickOptionOne && <ButtonOption {...clickOptionOne} />}
                    {clickOptionThree && (
                        <ButtonOption {...clickOptionThree} theme="invert" />
                    )}
                </div>
                <div className="sf__lower">
                    {clickOptionTwo && clickOptionTwo?.href !== pathname && (
                        <LinkOption {...clickOptionTwo} />
                    )}
                    {hasPhoneOption && <AcfTooltip iconFill="var(--interactive)" />}
                </div>
            </div>
            <style jsx>
                {`
                    .sf {
                        position: fixed;
                        background: #fff;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        max-width: 100%;
                        z-index: ${zIndex('stickyFooter')};
                        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                        transition-timing-function: ease-in;
                        transition: 0.5s;
                        transform: translateY(110%);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .sf {
                            bottom: 8px;
                            right: 24px;
                            max-width: 380px;
                            transform: translateY(150%);
                        }
                    }
                    .sf.show {
                        transition-timing-function: ease-out;
                        transition: 0.5s;
                        transform: translateY(0);
                    }
                    .sf__header {
                        padding: 8px 16px;
                        cursor: pointer;
                        position: relative;
                        background: var(--primary);
                        color: #fff;
                        display: grid;
                        grid-template-columns: auto 30px;
                        align-items: center;
                        grid-gap: 4px;
                    }
                    .sf__title {
                        font-size: 14px;
                        font-weight: bold;
                        text-align: center;
                    }
                    .sf__icon {
                        display: flex;
                        align-items: center;
                    }
                    .sf__body {
                        padding: 0 16px;
                        text-align: center;
                        height: 0;
                        overflow: hidden;
                    }
                    .sf__body.show {
                        height: auto;
                        padding: 16px;
                    }
                    .sf__subtitle {
                        margin-bottom: 16px;
                        font-size: 14px;
                    }
                    .sf__buttons {
                        display: grid;
                        grid-template-columns: ${!!clickOptionThree ? '1fr 1fr' : '1fr'};
                        grid-gap: 8px;
                    }
                    .sf__button {
                        padding: 8px;
                        flex: 1;
                        text-align: center;
                        font-size: 12px;
                        text-decoration: none;
                        display: block;
                        background: #fff;
                        color: var(--interactive-400);
                        border: 2px solid var(--interactive);
                        cursor: pointer;
                    }
                    .sf__button:first-child {
                        background: var(--interactive-300);
                        color: #fff;
                        border-radius: 4px 0 0 4px;
                        border-right: none;
                    }

                    .sf__button:last-child {
                        border-radius: 0 4px 4px 0;
                        border-left: none;
                    }
                    .sf__button:only-child {
                        border-radius: 4px;
                    }
                    .sf__lower {
                        display: grid;
                        grid-template-columns: ${hasPhoneOption ? 'auto auto' : '1fr'};
                        align-items: center;
                        justify-content: center;
                        grid-gap: 16px;
                        font-size: 14px;
                        margin-top: 16px;
                    }
                `}
            </style>
        </div>
    );
};
export default StickyFooter;

const ButtonOption = (props) => {
    const {isMobile} = useContext(AppContext);
    const style = {
        minWidth: '100%',
        fonWeight: 'normal',
        fontSize: isMobile ? '12px' : '16px',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '18px',
    };
    const {callTrackingNumber = {}} = ({} = useContext(AppContext));

    const href = props?.href || callTrackingNumber?.href;
    const text = props?.text || `Call ${callTrackingNumber?.display}`;
    return (
        <>
            {props?.onClick ? (
                <Button onClick={props?.onClick} {...props} style={style}>
                    {props?.text}
                </Button>
            ) : (
                <Button href={href} {...props} style={style}>
                    {text}
                </Button>
            )}
        </>
    );
};
const LinkOption = (props) => {
    const {callTrackingNumber = {}} = ({} = useContext(AppContext));
    const styles = {
        fontWeight: 'normal',
        textDecoration: 'underline',
        color: 'var(--interactive)',
        cursor: 'pointer',
    };
    if (props?.onClick) {
        return (
            <span onClick={props?.onClick} style={styles} title={props?.text}>
                {props?.text}
            </span>
        );
    } else if (props?.href) {
        return (
            <Link href={props?.href} style={styles} title={props?.text}>
                {props?.text}
            </Link>
        );
    } else {
        return (
            <a
                href={callTrackingNumber?.href}
                style={styles}
                title={`Call ${callTrackingNumber?.display}`}>
                Call {callTrackingNumber?.display}
            </a>
        );
    }
};
