import {useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import Button from 'aac-components/components/Button';
import IconSearch from 'aac-components/components/Icons/Basic/IconSearch';
import {BREAKPOINT} from '../../lib/styles';
import AppContext from '../AppContext';

const ButtonStyles = styled.div`
    button {
        background: var(--gray-100);
    }
    button:hover {
        background: var(--gray-100);
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        button {
            border-radius: 4px;
            background: var(--interactive-300);
        }
        button:hover {
            background: var(--interactive-400);
        }
    }
`;

const SiteSearch = ({
    placeholder = 'What are you looking for?',
    iconColor = 'var(--primary)',
}) => {
    const {isMobile} = useContext(AppContext);
    const router = useRouter();
    const querySearch = router?.query?.search;

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setError(false);
        setInputValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue) {
            setLoading(true);
            router.push(`/search?search=${inputValue}`);
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <div className="site-search">
            <form>
                <input
                    type="text"
                    name="site-search"
                    id="site-search"
                    className={error ? 'error' : ''}
                    placeholder={placeholder}
                    onChange={(e) => handleInputChange(e)}
                />
                <ButtonStyles>
                    <Button onClick={handleSubmit}>
                        {isMobile ? <IconSearch fill={iconColor} /> : 'Search'}
                    </Button>
                </ButtonStyles>
            </form>
            <style jsx>
                {`
                    .site-search {
                        border: none;
                        border-radius: 0;
                        width: 100%;
                        background: #fff;
                        box-shadow: var(--box-shadow);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .site-search {
                            box-shadow: var(--box-shadow);
                            border: 1px solid var(--gray-200);
                            border-radius: 8px;
                            max-width: 770px;
                        }
                    }

                    .site-search form {
                        display: flex;
                        justify-content: space-between;
                        grid-gap: 8px;
                        background: var(--gray-100);
                        padding: 0;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .site-search form {
                            background: #fff;
                            padding: 16px;
                        }
                    }
                    .site-search input {
                        width: 100%;
                        background: var(--gray-100);
                        font-size: 14px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .site-search input {
                            min-width: 400px;
                            background: #fff;
                            font-size: 18px;
                        }
                    }
                    .site-search input.error {
                        border: 1px solid var(--warning);
                    }
                    .site-search input,
                    .site-search input:active,
                    .site-search input:focus {
                        border: none;
                        outline: none;
                    }
                `}
            </style>
        </div>
    );
};
export default SiteSearch;
