import {useState, useRef} from 'react';
import {getPageByPath} from '../../lib/post';
import {WPFetch} from 'aac-components/utils/fetch';
import Insurance from '../../components/PageTemplates/FacilityTour/Insurance';
import Layout from '../../components/Layout';
import Image from 'next/image';
import MaxWidth from '../../components/MaxWidth';
import {BREAKPOINT, MAX_WIDTH_NARROW_PX} from 'aac-components/utils/styles';
import CallrailLink from '../../components/CallrailLink';
import Button from 'aac-components/components/Button';
import FAQs from 'aac-components/components/FAQs/FAQs';
import Filter from '../../components/FacilitiesByRegion/Filter';
import {isMappableArray} from 'aac-components/utils/helpers';
import StatesNotListedCta from '../../components/FacilitiesByRegion/StatesNotListedCta';
import FacilitiesByRegionOutpatient from '../../components/FacilitiesByRegionOutpatient';
import FacilityCardSlider from '../../components/FacilityCardSlider';
import ShortcodePortals from 'aac-components/components/ShortcodePortals/ShortcodePortals';

const OutpatientRehab = ({facilities = [], data = {}}) => {
    const facilitiesRef = useRef();

    const [selectedState, setSelectedState] = useState(null);

    const handleStateFilter = (selectedState) => {
        setSelectedState(selectedState);
    };

    const {acf: {faqs: {faqs_list: faqs = [], include_faq_schema = true} = {}} = {}} =
        ({} = data);

    let states =
        isMappableArray(facilities) &&
        facilities
            ?.reduce((acc, curr, i) => {
                const hasState = !!acc?.find((x) => x == curr?.acf?.aac_facility_state);
                if (!hasState) {
                    acc.push(curr?.acf?.aac_facility_state);
                }

                return acc;
            }, [])
            ?.sort();

    return (
        <Layout>
            <div className="hero">
                <MaxWidth>
                    <div className="hero__inner">
                        <div>
                            <h1
                                className="hero__title"
                                dangerouslySetInnerHTML={{
                                    __html: 'Outpatient Drug & Alcohol Rehab Centers Near Me',
                                }}
                            />
                            <div className="hero__copy">
                                <div>
                                    American Addiction Centers provides addiction and
                                    mental health care at various locations nationwide.
                                    See our treatment centers and locations below or give
                                    us a call to receive assistance over the phone.
                                    <div style={{display: 'flex', fontWeight: 'bold'}}>
                                        <span style={{marginRight: '4px'}}>Call</span>
                                        <CallrailLink
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'var(--interactive)',
                                                textDecoration: 'underline',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    To see if your insurance may cover the cost of
                                    rehabilitation at our various rehab centers, you can
                                    check your coverage online.
                                </div>
                            </div>
                            <div className="hero__button">
                                <Button
                                    onClick={(e) => window?.AAC?.openSlideInSsvob(e)}
                                    style={{width: '100%'}}>
                                    Check My Coverage
                                </Button>
                            </div>
                        </div>
                        <div className="hero__img">
                            <Image
                                src="/static/facility_hub.png"
                                fill
                                style={{objectFit: 'contain'}}
                                priority={true}
                                sizes="50vw 100vw"
                                alt="American Addiction Centers Photo"
                            />
                        </div>
                    </div>
                </MaxWidth>
            </div>

            <div id="our-facilities" className="facilities" ref={facilitiesRef}>
                <MaxWidth>
                    <h2 className="facilities__title">
                        Our Outpatient Facilities Nationwide
                    </h2>
                    <div className="facilities__subtitle">
                        Explore our locations with varying schedules and programming to
                        meet your needs. Whether you’re stepping down from a residential
                        program or looking to enter an outpatient rehab program near you,
                        we may have a solution for you.
                    </div>
                    <div className="facilities__container">
                        <div className="facilities__filter">
                            <Filter
                                label="Filter By Location"
                                onStateChange={handleStateFilter}
                                selectedState={selectedState}
                                options={[...states, 'State Not Listed', 'View All']}
                            />
                        </div>
                        <div>
                            {selectedState === 'States Not Listed' && (
                                <StatesNotListedCta />
                            )}
                            <FacilitiesByRegionOutpatient
                                facilities={facilities}
                                selectedState={selectedState}
                            />
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <div className="facilities__inpatient">
                <MaxWidth>
                    <h2>Our Inpatient Facilities</h2>
                    <FacilityCardSlider />
                </MaxWidth>
            </div>
            <MaxWidth>
                <div className="insurance__section">
                    <Insurance />
                </div>
            </MaxWidth>
            <div className="faqs">
                <FAQs faqs={faqs} includeSchema={include_faq_schema} />
            </div>
            <ShortcodePortals />
            <style jsx>
                {`
                    .hero {
                        background: linear-gradient(
                            97.16deg,
                            #e5ebf5 0.29%,
                            #f8eee5 99.87%
                        );
                    }
                    .hero__inner {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__inner {
                            grid-template-columns: 530px auto;
                            align-items: flex-start;
                            padding: 80px 0;
                            grid-gap: 80px;
                        }
                    }
                    .hero__title {
                        font-size: 36px;
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    .hero__copy > div {
                        margin-bottom: 24px;
                        line-height: 24px;
                    }
                    .hero__img {
                        position: relative;
                        min-height: 200px;
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__img {
                            min-height: 300px;
                        }
                    }

                    .hero__button {
                        max-width: 100%;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__button {
                            max-width: 500px;
                            grid-template-columns: auto auto;
                            grid-gap: 16px;
                        }
                    }
                    .facilities,
                    .faqs {
                        padding: 40px 16px;
                        scroll-margin: 145px;
                        justify-content: center;
                        align-items: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facilities {
                            padding: 80px 0;
                            scroll-margin: 250px;
                        }
                    }
                    .faqs {
                        max-width: ${MAX_WIDTH_NARROW_PX};
                        margin: 0 auto 80px auto;
                    }
                    .facilities {
                        background: #fff;
                    }
                    .facilities__inpatient {
                        background: var(--tertiary-200);
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facilities__inpatient {
                            padding: 80px 0;
                        }
                    }
                    .facilities__inpatient h2 {
                        text-align: center;
                        margin-bottom: 40px;
                    }
                    .facilities__title {
                        margin-bottom: 24px;
                        font-size: 30px;
                        font-weight: bold;
                        text-align: center;
                    }
                    .facilities__subtitle {
                        text-align: center;
                        line-height: 24px;
                        max-width: 720px;
                        margin: 0 auto 80px auto;
                    }
                    .facilities__container {
                        max-width: 100%;
                        display: grid;
                        grid-template-columns: 1fr;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .facilities__container {
                            grid-template-columns: 1fr 2fr;
                            grid-gap: 80px;
                        }
                    }
                    .facilities__filter {
                        margin-bottom: 24px;
                    }
                    .insurance__section {
                        max-width: 1170px;
                        padding: 0 15px;
                        margin: 0 auto 120px auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance__section {
                            margin: 0 auto 100px auto;
                        }
                    }
                `}
            </style>
        </Layout>
    );
};
export default OutpatientRehab;

export const outpatientFacilitiesSlugs = [
    'outpatient-south-kingstown',
    'outpatient-greenville',
    'outpatient-oxford',
    'outpatient-las-vegas',
    'outpatient-arlington',
    'adcare-rhode-island-outpatient',
    'outpatient-riverview',
    'outpatient-hollywood',
    'outpatient-worcester',
    'outpatient-warwick',
    'adcare-hospital-outpatient',
    'outpatient-southaven',
];

export async function getServerSideProps(context) {
    let data = await getPageByPath(context, '?_fields=title,id,acf,yoast_head');
    const {clearCache = false} = context?.req?.query || false;

    const id = data?.id || 0;

    let facilities = [];

    try {
        const wpFetch = await WPFetch(
            `/wordpress/wp/v2/facilities?per_page=50&slug=${outpatientFacilitiesSlugs?.join(
                ',',
            )}&_fields=id,title,yoast_head,acf,featured_image,slug,link,excerpt`,
            clearCache,
        );
        facilities = wpFetch.data;
    } catch (e) {
        console.warn(e);
    }

    // set 4040 status code
    if (id === 0) {
        context.res.statusCode = 404;
    }
    return {
        props: {
            data,
            facilities,
        },
    };
}
