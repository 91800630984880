import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import React from 'react';
import Link from 'next/link';
import {hasChildren} from './index';

const FooterDesktop = ({menu = []}) => {
    return (
        <div className="container">
            {Array.isArray(menu) &&
                menu.map((section, sectionIndex) => {
                    return (
                        <section key={section?.ID}>
                            <div
                                className="section__title"
                                dangerouslySetInnerHTML={{__html: section?.title}}
                            />
                            {hasChildren(section) && (
                                <div
                                    className={`secondary-items ${
                                        sectionIndex === 0 ? 'first' : ''
                                    }`}>
                                    {section?.child_items.map((sub_section) => {
                                        return (
                                            <div key={sub_section?.ID}>
                                                {sub_section?.url === '#' ? (
                                                    <div
                                                        className="secondary-item__title"
                                                        dangerouslySetInnerHTML={{
                                                            __html: sub_section?.title,
                                                        }}
                                                    />
                                                ) : (
                                                    <Link
                                                        href={sub_section?.url}
                                                        legacyBehavior>
                                                        <a
                                                            className="secondary-item__title"
                                                            title={sub_section?.title}
                                                            dangerouslySetInnerHTML={{
                                                                __html: sub_section?.title,
                                                            }}
                                                        />
                                                    </Link>
                                                )}
                                                {hasChildren(sub_section) && (
                                                    <React.Fragment>
                                                        {sub_section?.child_items?.map(
                                                            (tertiary_item) => {
                                                                return (
                                                                    <Link
                                                                        key={
                                                                            tertiary_item?.url
                                                                        }
                                                                        href={
                                                                            tertiary_item?.url
                                                                        }
                                                                        legacyBehavior>
                                                                        <a className="tertiary-item__wrapper">
                                                                            {sectionIndex ===
                                                                                0 && (
                                                                                <div className="tertiary-item__icon">
                                                                                    <IconLocation
                                                                                        width="14"
                                                                                        height="14"
                                                                                        fill="var(--gray-100)"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <span
                                                                                className="tertiary-item__title"
                                                                                title={
                                                                                    tertiary_item?.title
                                                                                }
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: tertiary_item?.title,
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </Link>
                                                                );
                                                            },
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </section>
                    );
                })}
            <style jsx>
                {`
                    .container {
                        padding: 60px 0;
                        display: flex;
                        flex-flow: column wrap;
                        max-height: 650px;
                        grid-gap: 40px 80px;
                        max-width: 100%;
                    }
                    section {
                        max-width: 33%;
                    }
                    .section__title {
                        font-size: 18px;
                        font-weight: 700;
                        border-top: 3px solid var(--primary-100);
                        padding-top: 12px;
                        margin: 0px 0 20px 0;
                        text-transform: uppercase;
                    }
                    .secondary-items.first {
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -webkit-flex-flow: column wrap;
                        -moz-box-orient: vertical;
                        -moz-box-direction: normal;
                        flex-flow: column wrap;
                        max-height: 600px;
                        grid-gap: 16px;
                    }
                    .secondary-items.first > div {
                        max-width: 50%;
                    }
                    .secondary-item__title {
                        color: var(--gray-100);
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                    a.secondary-item__title {
                        font-weight: normal;
                        text-decoration: none;
                        display: block;
                        font-size: 14px;
                    }
                    .tertiary-item__title {
                        color: var(--gray-100);
                        text-decoration: none;
                        display: block;
                        font-size: 14px;
                    }
                    a.tertiary-item__wrapper {
                        display: flex;
                        margin-bottom: 16px;
                        text-decoration: none;
                        color: var(--gray-100);
                        align-items: center;
                    }
                    .tertiary-item__icon {
                        margin-right: 8px;
                    }
                `}
            </style>
        </div>
    );
};
export default FooterDesktop;
