import url from 'url';
import Link from 'next/link';
import {useRouter} from 'next/router';

const NextStepsNavMobile = ({items = []}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    // List of words to replace
    const wordsToReplace = [
        'my',
        'rehab',
        'resources',
        'form',
        'treatment',
        'customize',
        'process',
    ]; // Add all words you want to replace here

    // Create a dynamic regular expression from the list of words
    const regex = new RegExp(`\\b(${wordsToReplace.join('|')})\\b`, 'gi');

    // Function to capitalize the first letter of each word
    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const isEsp = pathname.includes('esp');

    // Map through items to create modified items with updated `copy` text
    const modifiedItems = items.map((item) => {
        let modifiedCopy;

        if (item.copy.toLowerCase() === 'rehab inquiry form') {
            modifiedCopy = 'Rehab Inquiry';
        } else if (item.copy.toLowerCase() === 'revisa mi seguro') {
            modifiedCopy = 'Consultar Seguro';
        } else if (item.copy.toLowerCase() === 'ver centros de tratamiento') {
            modifiedCopy = 'Ver Centros';
        } else if (item.copy.toLowerCase() === 'ver proceso de admisión') {
            modifiedCopy = 'Ver Admisiones';
        } else if (item.copy.toLowerCase() === 'resources for veterans') {
            modifiedCopy = 'For Veterans';
        } else {
            // Otherwise, replace all occurrences of the words in the list with an empty string
            modifiedCopy = item.copy.replace(regex, '');
        }

        // Capitalize the first letter of each word in `copy`
        modifiedCopy = capitalizeWords(modifiedCopy);

        return {
            ...item,
            copy: modifiedCopy,
            // Override the Icon properties
            Icon: (props) =>
                item.Icon({
                    fill: 'var(--primary)', // Updated fill color
                    width: '24', // Updated width
                    height: '24', // Updated height
                    ...props, // Ensure all other props are still passed
                }),
        };
    });

    return (
        <div>
            <div className="next-steps-nav__mobile">
                {Array.isArray(modifiedItems) &&
                    modifiedItems.map((option, index) => {
                        const {onClick, href, Icon, copy} = option;
                        if (href === pathname) return null;
                        return (
                            <div key={index} className="next-steps-nav__mobile--option">
                                {onClick && (
                                    <div onClick={onClick} className="next-steps__item">
                                        <span className="next-steps-nav__mobile--icon">
                                            <Icon />
                                        </span>
                                        <span
                                            className="next-steps-nav__mobile--copy"
                                            dangerouslySetInnerHTML={{
                                                __html: copy,
                                            }}
                                        />
                                    </div>
                                )}
                                {href && (
                                    <Link
                                        href={href}
                                        title={copy}
                                        style={{
                                            color: 'var(--primary-300)',
                                            textDecoration: 'none',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                            display: 'flex',
                                        }}>
                                        <span className="next-steps-nav__mobile--icon">
                                            <Icon />
                                        </span>{' '}
                                        <span
                                            className="next-steps-nav__mobile--copy"
                                            dangerouslySetInnerHTML={{__html: copy}}
                                        />
                                    </Link>
                                )}
                            </div>
                        );
                    })}{' '}
            </div>
            <style jsx>
                {`
                    .next-steps-nav__title {
                        height: 35px;
                        padding: 8px 15px 8px 15px;
                        font-size: 12px;
                        font-weight: 700;
                        background: #fff;
                        line-height: 19px;
                    }
                    .next-steps-nav__mobile {
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 2px;
                        border-bottom: 4px solid var(--gray-200);
                        border-top: 4px solid var(--gray-200);
                    }
                    .next-steps-nav__mobile--option {
                        text-align: center;
                        padding: 8px;
                        cursor: pointer;
                        height: 40px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 4px;
                    }
                    .next-steps-nav__mobile--icon {
                        display: flex;
                        flex-direction: row;
                        width: 24px;
                        height: 24px;
                        color: var(--primary-300);
                    }
                    .next-steps-nav__mobile--option > a,
                    .next-steps-nav__mobile--option > .next-steps__item {
                        color: var(--primary-300);
                        text-decoration: none;
                        max-width: 100%;
                    }
                    .next-steps__item,
                    .next-steps-nav__mobile--copy {
                        align-items: center;
                        font-size: 10px;
                        display: flex;
                        padding-left: 4px;
                    }
                    .next-steps-nav__mobile--option:nth-child(2) {
                        border-left: 4px solid var(--gray-200);
                        border-right: 4px solid var(--gray-200);
                    }
                    .next-steps-nav__container {
                        min-height: 0 !important;
                    }
                `}
            </style>
        </div>
    );
};
export default NextStepsNavMobile;
