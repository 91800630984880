import HardcodedPillStyles from './HardcodedPillStyles';
import Link from 'next/link';
import styled from 'styled-components';

const LevelsOfCare = () => {
    return (
        <div>
            <HardcodedPillStyles>
                <div>
                    <div className="title">Levels of Care</div>
                    <div className="copy">
                        <b>Detox</b>
                        <br />
                        Minimize the discomfort and health risks of withdrawal and set the
                        foundation for addiction recovery.
                    </div>
                    <div className="copy">
                        <b>Inpatient Treatment</b>
                        <br />
                        Our inpatient program offers a structured, immersive environment
                        where you can focus solely on healing. 
                        <Link href="/treatment-centers" title="Treatment Centers">
                            Find rehab centers that offer medical detox and inpatient
                            services
                        </Link>
                        .
                    </div>
                    <div className="copy">
                        <b>Outpatient Treatment</b>
                        <br />
                        Achieve recovery while maintaining your daily responsibilities
                        with our flexible outpatient programs. 
                        <Link
                            href="/treatment-centers/outpatient-rehab"
                            title="Outpatient Rehab">
                            Find rehab centers that offer outpatient treatment services
                        </Link>
                        .
                    </div>
                </div>
            </HardcodedPillStyles>
        </div>
    );
};
export default LevelsOfCare;

const LinkStyles = styled.div`
    a {
        color: var(--secondary-300);
        text-decoration: none;
    }
`;
