import Banner from 'aac-components/components/Banner';
import {useRouter} from 'next/router';
import {useContext, useEffect, useState} from 'react';
import {getSegmentName, getTags} from '../../lib/utils';
import {replaceDynamicKeywords} from '../../lib/dynamic-keywords';
import styled from 'styled-components';
import AppContext from '../AppContext';
import {BREAKPOINT} from '../../lib/styles';
import {zIndex} from 'aac-components/utils/helpers';
import CallrailLink from '../CallrailLink';
import AcfTooltip from '../Tooltips/AcfTooltip';

const SegmentBanner = () => {
    const [messages, setMessages] = useState([]);
    const [isOutpatientPage, setIsOutpatientPage] = useState(false);

    const segment = getSegmentName();
    const {asPath} = useRouter();

    const context = useContext(AppContext);
    const {
        callTrackingNumber: {display = '', href = ''} = {},
        dynamicKeywords = {},
        acfOptions: {
            acf: {segment_banner_messages = {}, default_banner_messages = {}} = {},
        } = {},
        pageProps: {
            data: {
                acf: {hide_global_ctas: {top_banner: hideTopBanner = false} = {}} = {},
                template = '',
            } = {},
        } = {},
    } = context;

    useEffect(() => {
        const groups = segment_banner_messages?.groups || [];
        const matchingGroups = groups.filter((group) => group?.segment.includes(segment));
        let segmentMessages = [];
        Array.isArray(matchingGroups) &&
            matchingGroups.map((group) =>
                group?.messages.forEach((item) => {
                    let message = item?.message || '';

                    // repace {phone}
                    message = message?.replace(
                        /{phone}/g,
                        `<a href="${href}">${display}</a>`,
                    );

                    // replace {dynamic} keywords
                    message = replaceDynamicKeywords(message, dynamicKeywords);

                    segmentMessages.push(message);
                }),
            );

        // use default if none by segment
        if (
            !segmentMessages ||
            (segmentMessages?.length < 1 &&
                Array.isArray(default_banner_messages?.messages))
        ) {
            segmentMessages = default_banner_messages?.messages.map(
                (message) => message?.message,
            );
        }
        setMessages(segmentMessages);
    }, [asPath, context]);

    useEffect(() => {
        const tags = getTags();
        setIsOutpatientPage(Array.isArray(tags) && tags?.includes('outpatient'));
    }, [asPath]);

    const messagesArray = messages.map((message) => {
        const hasPhone = message?.includes('tel:');
        return () => (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div dangerouslySetInnerHTML={{__html: message}} />
                {hasPhone && <AcfTooltip hideText={true} />}
            </div>
        );
    });

    if (!context?.acfOptions || hideTopBanner || template === 'compliance.php')
        return null;

    return (
        <div id="segment-banner">
            <BannerStyles>
                {isOutpatientPage ? (
                    <Banner
                        messages={[
                            () => (
                                <>
                                    <span>Inquire about our outpatient services.</span>{' '}
                                    <CallrailLink />
                                </>
                            ),
                        ]}
                        iconFill="var(--secondary-300)"
                    />
                ) : (
                    <div className="banner__container">
                        <Banner
                            messages={messagesArray}
                            iconFill="var(--secondary-300)"
                        />
                    </div>
                )}
            </BannerStyles>
        </div>
    );
};
export default SegmentBanner;

const BannerStyles = styled.div`
    .banner {
        background: transparent !important;
        border-bottom: none !important;
    }
    .banner a {
        color: var(--primary-300);
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .banner a {
            color: var(--secondary-300);
        }
    }
    .banner__container {
        position: relative;
        display: grid;
        align-items: center;
        grid-gap: 4px;
        grid-template-columns: auto 20px;
        width: 100%;
    }
    .message {
        font-size: 22px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .message {
            font-size: 16px;
        }
    }
    .banner__tooltip {
        position: absolute;
        right: 10px;
    }
`;
