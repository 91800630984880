import IconHospital from 'aac-components/components/Icons/Building/IconHospital';
import IconClipboard from 'aac-components/components/Icons/Misc/IconClipboard';
import IconHeart from 'aac-components/components/Icons/Basic/IconHeart';
import IconShield from 'aac-components/components/Icons/Basic/IconShield';

export const navItems = {
    veterans: [
        {
            href: '/verify-insurance/veterans',
            Icon: (props) => (
                <IconShield fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Veteran Benefits',
        },
        {
            href: '/treatment-centers',
            Icon: (props) => (
                <IconHospital fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'View Rehab Centers',
        },
        {
            href: '/admissions/veterans',
            Icon: (props) => (
                <IconClipboard fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Resources for Veterans',
        },
    ],
    outpatient: [
        {
            href: '/verify-insurance-1',
            Icon: (props) => (
                <IconClipboard fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Rehab Inquiry Form',
        },
        {
            href: '/treatment-centers/outpatient-rehab',
            Icon: (props) => (
                <IconHospital fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'View Treatment Centers',
        },
        {
            href: '/rehab-toolkit',
            Icon: (props) => (
                <IconHeart fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Rehab Toolkit',
        },
    ],
    lowSegment: [
        {
            href: '/verify-insurance-1',
            Icon: (props) => (
                <IconClipboard fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Rehab Inquiry Form',
        },
        {
            href: '/treatment-centers',
            Icon: (props) => (
                <IconHospital fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'View Treatment Centers',
        },
        {
            href: '/rehab-toolkit',
            Icon: (props) => (
                <IconHeart fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Rehab Toolkit',
        },
    ],
    spanish: [
        {
            href: '/verify-insurance/esp',
            Icon: (props) => (
                <IconShield fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Revisa mi Seguro',
        },
        {
            href: '/treatment-centers/esp',
            Icon: (props) => (
                <IconHospital fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Ver Centros de Tratamiento',
        },
        {
            href: '/admissions/esp',
            Icon: (props) => (
                <IconClipboard fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Ver Proceso de Admisión',
        },
    ],
    default: [
        {
            href: '/verify-insurance',
            Icon: (props) => (
                <IconShield fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'Check My Insurance',
        },
        {
            href: '/treatment-centers',
            Icon: (props) => (
                <IconHospital fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'View Treatment Centers',
        },
        {
            href: '/admissions',
            Icon: (props) => (
                <IconClipboard fill="var(--primary)" width="20" height="20" {...props} />
            ),
            copy: 'See Admissions Process',
        },
    ],
};
