import styled from 'styled-components';

const HardcodedPillStyles = styled.div`
    .title {
        font-weight: bold;
        margin-bottom: 24px;
    }
    .copy {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
`;
export default HardcodedPillStyles;
